import * as React from 'react';
import {Box, createStyles, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {useEffect, useState} from 'react';
import {ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {css} from 'glamor';
import SVGIcon from '../ui/SVGIcon';
import {
  AttentionRequiredReason,
  PaymentMethodTypes,
  PaymentProviderName,
  VerificationStatus,
} from '../../model/constants/Constants';
import CheckIcon from '@material-ui/icons/Check';
import LoadingIndicator from '../ui/LoadingIndicator';
import {usePaymentUtilities} from '../../hooks/usePaymentUtilities';
import {PennyVerificationDialog, PennyVerificationDialogStages} from '../dialog/PennyVerificationDialog';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {InfoEditIcon} from '../../assets/svg/accounts-payable/InfoEditIcon';
import clsx from 'clsx';
import {PaymentMethodThumbnailIcons} from './PaymentMethodThumbnailIcons';

export type PMThumbnailProps = {
  method: ITenantSupplierPaymentMethod;
  isExpired?: boolean;
  onChangePaymentMethod?: (pm: ITenantSupplierPaymentMethod) => void;
  isDeletingMethod?: boolean;
  hideIsSelectedCheck?: boolean;
  onRemoveMethod?: (card: ITenantSupplierPaymentMethod) => void;
  isPaymentMethodNeededToBeVerified?: boolean;
  loadPaymentMethods?: () => void;
  onEditPaymentMethod?: (
    method: ITenantSupplierPaymentMethod | undefined,
    account: IAccountResponse | undefined,
    isAfterCreatingAccount: boolean,
  ) => Promise<boolean>;
  isAccountsPayable?: boolean;
  onPaymentMethodVerify?: (method: ITenantSupplierPaymentMethod | undefined) => void;
  newPaymentMethodsStyling?: boolean;
  paymentAuth72488?: boolean;
  onCheckError?: (pm: ITenantSupplierPaymentMethod) => void;
};

export const PaymentMethodThumbnail = (props: PMThumbnailProps) => {
  const {compareExpiryDate, daysLeftInCreditCard} = usePaymentUtilities();

  const [dialogStage, setDialogStage] = useState<PennyVerificationDialogStages>(PennyVerificationDialogStages.NONE);

  const checkIcon = css({
    color: '#FFF',
    height: '0.75rem',
    width: '0.75rem',
  });

  let background = '#F3F2F1';
  let lightDot = false;

  switch (props.method?.PaymentMethod) {
    case PaymentMethodTypes.Visa:
      background = 'linear-gradient(25deg, #0f509e, #1399cd)';
      lightDot = props.isAccountsPayable ? false : true;
      break;
    case PaymentMethodTypes.Mastercard:
      background = 'linear-gradient(25deg, #fbfbfb, #e8e9e5)';
      break;
    case PaymentMethodTypes.AmericanExpress:
      background = 'linear-gradient(25deg, #308c67, #a3f2cf)';
      lightDot = true;
      break;
    case PaymentMethodTypes.UnionPay:
      background = 'linear-gradient(25deg, #939393, #717171)';
      lightDot = true;
      break;
    case PaymentMethodTypes.DinersClub:
      background = 'linear-gradient(25deg, #fff, #eee)';
      break;
    case PaymentMethodTypes.BankTransfer:
      background = '#FFF';
      break;
    default:
      background = '#F3F2F1';
      break;
  }

  const dot = css({
    backgroundColor: lightDot ? '#FFF' : '#333',
    borderRadius: '50%',
    display: 'inline-block',
    height: '3px',
    margin: '0.5px',
    padding: '2px',
    width: '3px',
  });

  // We have bank accounts now which don't have an expiry.
  // If we don't have an expiry make it valid because we need to deal with notverified bank accounts.
  //Alex: Todo here
  const compareCardExpiryDate = props.method?.Expiry ? compareExpiryDate(props.method.Expiry) : 1;
  const daysLeftInCard = props.method?.Expiry ? daysLeftInCreditCard(props.method.Expiry) : 1;

  const isCreditCard = props.method?.PaymentMethod !== PaymentMethodTypes.BankTransfer;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1350));
  const lgmatches = useMediaQuery(theme.breakpoints.up(1440));

  const verifyAccountAPI = useSpendaPaymentServicesAPI();
  const [account, setAccount] = useState<IAccountResponse | undefined>(undefined);

  const AutoPayEnabled = props.method.IsInvigoDefault;

  const canEditPaymentMethod = props?.method?.PaymentAccountVerificationStatus === VerificationStatus.VERIFIED;
  const paymentMethodNeededToBeVerify =
    props?.method?.PaymentAccountVerificationStatus === VerificationStatus.NOT_VERIFIED ||
    props?.method?.PaymentAccountVerificationStatus === VerificationStatus.PENDING ||
    props?.method?.PaymentAccountVerificationStatus === VerificationStatus.NOT_STARTED;
  const paymentMethodCheckError =
    props?.method?.PaymentAccountVerificationStatus === VerificationStatus.FAILED ||
    props?.method?.PaymentAccountVerificationStatus === VerificationStatus.RETRY_REQUIRED ||
    props?.method?.PaymentAccountVerificationStatus === VerificationStatus.ATTEMPTS_EXCEEDED;
  const isNoAuthorisationRules =
    props?.method?.AttentionRequiredReason === AttentionRequiredReason.NoAuthAttention && props.paymentAuth72488;
  const paymentMethodNotInvigo = props?.method?.PaymentMethod === PaymentMethodTypes.Invigo;

  const useStyles = makeStyles(() =>
    createStyles({
      shape: {
        background: `${background}`,
        border: PaymentMethodTypes.BankTransfer && '1px solid #333',
        borderRadius: 6,
        boxShadow: PaymentMethodTypes.BankTransfer ? 'none' : '0 0 5px rgb(0 0 0 / 20%)',
        cursor: 'pointer',
        height: props.isAccountsPayable ? 130 : 70,
        width: props.isAccountsPayable ? 210 : undefined,
        margin: '8px auto',
        opacity: compareCardExpiryDate < 0 ? '0.5' : '1',
        minWidth: 118,
        transform: matches ? 'scale(0.9)' : 'scale(1)',
        '&:hover': {
          transform: matches ? 'scale(1)' : 'scale(1.025)',
        },
      },
      apCardShade: {
        border: '1px solid #EAEAEA',
        background: '#FAFAFA',
        transition: '.5s ease-in-out',
        transform: 'unset',
        margin: '8px auto 0px',
        '&:hover': {
          transform: 'unset',
          border: '1px solid hsl(var(--primary))',
        },
      },
    }),
  );

  const tapActionStyle = css({
    padding: '5px',
    borderRadius: '7px',
    top: '-8px',
    left: paymentMethodNeededToBeVerify ? '16px' : '21px',
  });

  const apTapActionStyle = css({
    transform: 'translate(63%, -13px)',
    fontSize: '10px',
    border: '1px solid hsl(var(--primary))',
    borderRadius: '5px',
    color: 'hsl(var(--primary))',
    transition: '.5s ease-in-out',
    '&:hover': {
      backgroundColor: 'hsl(var(--primary))',
      color: '#fff',
    },
  });

  const autoPayStyle = css({
    transform: 'translate(89%, -13px)',
    transition: '.5s ease-in-out',
    '&:hover': {
      color: '#fff!important',
    },
  });

  const apErrorCheck = css({
    transform: 'translate(72%, -13px)',
    fontSize: '10px',
    border: '1px solid #ed5a07',
    borderRadius: '5px',
    color: '#ed5a07',
    transition: '.5s ease-in-out',
    '&:hover': {
      backgroundColor: '#ed5a07',
      color: '#fff',
    },
  });

  const apEditIconWrap = css({
    transform: 'unset!important',
    left: '79%!important',
  });
  const editIconInner = css({
    background: 'transparent!important',
  });
  const editIconBox = css({});

  const classes = useStyles();
  const classesCard = `${classes.shape}`;

  const onClickMethod = () => {
    if (props.method?.IsSelected) {
      return;
    }

    if (props.onChangePaymentMethod) {
      props.onChangePaymentMethod(props.method!);
    }
  };

  const clickCardAction = () => {
    //OnCheckError is added for Payment Widget, code below if block to be removed, once new UX gets live.
    if (props.onCheckError) {
      props.onCheckError(props.method);
      return;
    }
    setDialogStage(PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS);
    loadAccount();
  };

  const loadAccount = () => {
    if (props.method?.PaymentAccountGUID) {
      verifyAccountAPI
        .getAccount(PaymentProviderName.Spenda_Payment_Services, props.method.PaymentAccountGUID)
        .then(res => {
          setAccount(res);
        });
    }
  };

  useEffect(() => {
    if (dialogStage === PennyVerificationDialogStages.NONE) {
      // We show the dialog if we have an account, so clear the account to hide the dialog
      setAccount(undefined);
    }
  }, [dialogStage]);

  const onRemoveMethod = () => {
    if (props.onRemoveMethod && props.method) {
      props.onRemoveMethod(props.method);
    }
  };

  const editPaymentIcon = () => {
    return (
      canEditPaymentMethod &&
      props.onEditPaymentMethod && (
        <Box
          position={'absolute'}
          bottom={props.isAccountsPayable ? '66%' : '-0.9rem'}
          left={`${matches ? '0px' : '-3px'}`}
          zIndex={999}
          {...css({transform: `${matches ? 'scale(0.85)' : 'scale(0.9)'}`})}
          className={props.isAccountsPayable ? `${apEditIconWrap}` : ''}
        >
          <Box
            position="relative"
            width={props.isAccountsPayable ? '34px' : '28px'}
            height={props.isAccountsPayable ? '34px' : '28px'}
            borderRadius="6px"
            style={{
              backgroundColor: '#2F97BC',
            }}
            className={`${
              props.isAccountsPayable ? `${editIconInner}` : 'bg-spenda-default opacity-100 hover:bg-teal-900'
            }`}
          >
            <Box
              className={`cursor-pointer ${props.isAccountsPayable ? `${editIconBox}` : 'absolute left-px top-0'}`}
              onClick={() => props.onEditPaymentMethod!(props.method, undefined, false)}
              {...css({transform: `${props.isAccountsPayable ? 'unset' : 'scale(.75)'}`})}
            >
              {props.isAccountsPayable ? (
                <InfoEditIcon data-autoid={`btnEditIcon-${props.method.FriendlyName}`} />
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      )
    );
  };

  const cardThumbnail = (
    <Box position="relative">
      <Box
        className={`${classes.shape} ${
          props.isAccountsPayable && `${classes.apCardShade} relative z-10 flex flex-col justify-center`
        }`}
        position="relative"
        onClick={onClickMethod}
      >
        {paymentMethodNeededToBeVerify || paymentMethodCheckError || props.hideIsSelectedCheck
          ? ''
          : props.method?.IsSelected && (
              <Box
                position="absolute"
                top="-7px"
                left="-3px"
                style={{
                  backgroundColor: '#4DA128',
                  borderRadius: '50%',
                  width: '25px',
                  height: '25px',
                  zIndex: 1,
                  boxShadow: '#555 1px 2px 2px 1px',
                  opacity: 1,
                }}
              >
                <CheckIcon fontSize="small" className={`${checkIcon}`}></CheckIcon>
              </Box>
            )}
        {compareCardExpiryDate < 0 && (
          <Box
            zIndex={'100'}
            position="absolute"
            left={0}
            right={0}
            top={15}
            className="cursor-pointer bg-spenda-error text-center text-white"
            onClick={onClickMethod}
          >
            EXPIRED
          </Box>
        )}
        {compareCardExpiryDate === 0 && (
          <Box
            position="absolute"
            left={0}
            right={0}
            top={15}
            zIndex={'999'}
            className="cursor-pointer bg-spenda-warning text-center text-white"
            onClick={onClickMethod}
          >
            EXPIRES IN {Math.abs(daysLeftInCard)} DAYS
          </Box>
        )}
        {paymentMethodNeededToBeVerify && (
          <Box
            position="absolute"
            className={`cursor-pointer text-center ${
              props.isAccountsPayable
                ? `${apTapActionStyle} absolute top-0 bg-white px-3 py-1 font-poppins`
                : `${tapActionStyle} bg-spenda-warning text-white  hover:bg-yellow-800`
            }`}
            onClick={() =>
              props.isAccountsPayable && props.onPaymentMethodVerify
                ? props.onPaymentMethodVerify(props.method)
                : clickCardAction()
            }
            data-autoid="lblVerify"
          >
            Tap To Verify
          </Box>
        )}
        {isNoAuthorisationRules &&
          !paymentMethodNeededToBeVerify &&
          !paymentMethodCheckError &&
          !AutoPayEnabled &&
          !paymentMethodNotInvigo && (
            <Box
              position="absolute"
              className={`cursor-pointer text-center ${
                props.isAccountsPayable
                  ? `${apTapActionStyle} absolute left-[-57px] top-0 bg-white px-3 py-1 font-poppins`
                  : `${tapActionStyle} bg-spenda-warning text-white  hover:bg-yellow-800`
              }`}
              onClick={() => props.onEditPaymentMethod!(props.method, undefined, true)}
              data-autoid="lblSetRules"
            >
              Set Authorisation Rules
            </Box>
          )}
        {paymentMethodCheckError && (
          <Box
            position="absolute"
            className={` ${
              props.isAccountsPayable
                ? `${apErrorCheck} absolute top-0 bg-white px-3 py-1 font-poppins`
                : `${tapActionStyle} cursor-pointer bg-spenda-error text-center text-white hover:bg-red-800`
            } `}
            onClick={clickCardAction}
            data-autoid="lblError"
          >
            Check Error
          </Box>
        )}
        {AutoPayEnabled && (
          <Box
            position="absolute"
            className={clsx(
              ` text-center text-white`,
              `${autoPayStyle} hover:!text-spenda-white absolute top-0 !rounded-[5px] border border-solid !border-[#1C78AD] bg-white px-3 py-1 font-poppins text-[10px] !text-primary hover:bg-[#1C78AD]`,
            )}
            data-autoid="lblAutoPay"
          >
            Auto-Pay
          </Box>
        )}
        {props?.newPaymentMethodsStyling ? (
          <PaymentMethodThumbnailIcons pm={props?.method} />
        ) : (
          <Box display="flex" justifyContent={props.isAccountsPayable ? 'center' : 'flex-end'} paddingRight="0.375rem">
            <SVGIcon paymentMethod={props.method?.PaymentMethod} isAccountsPayable={props.isAccountsPayable} />
          </Box>
        )}
        {!props?.newPaymentMethodsStyling ? (
          <Box
            display="flex"
            justifyContent="center"
            fontSize={props.isAccountsPayable ? '14px' : '12px'}
            className={props.isAccountsPayable ? 'pt-3 font-poppins' : ''}
          >
            {Array.from({length: 2}).map((val: unknown, setId: number) => (
              <Box key={`set-${setId}`} paddingRight="4px">
                {Array.from({length: 4}).map((val: unknown, dotId: number) => (
                  <span key={`set-${setId}-dot-${dotId}`} className={`${dot}`}></span>
                ))}
              </Box>
            ))}
            <Box paddingTop="1px" color={lightDot ? '#FFF' : '#333'}>
              {props.method?.Last4}
            </Box>
          </Box>
        ) : null}
      </Box>
      {editPaymentIcon()}
      <Box
        position="absolute"
        bottom="-1rem"
        right={`${matches ? '0px' : '-3px'}`}
        {...css({transform: `${matches ? 'scale(0.85)' : 'scale(0.9)'}`})}
      >
        {dialogStage !== PennyVerificationDialogStages.NONE && (
          <PennyVerificationDialog
            loadPaymentMethods={props.loadPaymentMethods}
            bankAccountDetails={account}
            dialogStage={dialogStage}
            setDialogStage={setDialogStage}
            reloadAccount={loadAccount}
            onRemoveAccount={onRemoveMethod}
          ></PennyVerificationDialog>
        )}
      </Box>
    </Box>
  );

  const bankThumbnail = (
    <Box position="relative">
      <Box
        className={`${classesCard} ${props.isAccountsPayable && `${classes.apCardShade} flex flex-col justify-center`}`}
        position="relative"
        onClick={onClickMethod}
      >
        {paymentMethodNeededToBeVerify || paymentMethodCheckError || props.hideIsSelectedCheck
          ? ''
          : props.method?.IsSelected && (
              <Box
                position="absolute"
                top="-7px"
                left="-3px"
                style={{
                  backgroundColor: '#4DA128',
                  borderRadius: '50%',
                  width: '25px',
                  height: '25px',
                  zIndex: 1,
                  boxShadow: '#555 1px 2px 2px 1px',
                }}
              >
                <CheckIcon fontSize="small" className={`${checkIcon}`}></CheckIcon>
              </Box>
            )}
        {paymentMethodNeededToBeVerify && (
          <Box
            position="absolute"
            className={`cursor-pointer text-center ${
              props.isAccountsPayable
                ? `${apTapActionStyle} absolute top-0 bg-white px-3 py-1 font-poppins`
                : `${tapActionStyle} bg-spenda-warning text-white hover:bg-yellow-800`
            }`}
            onClick={() =>
              props.isAccountsPayable && props.onPaymentMethodVerify
                ? props.onPaymentMethodVerify(props.method)
                : clickCardAction()
            }
            data-autoid="lblVerify"
          >
            Tap To Verify
          </Box>
        )}
        {isNoAuthorisationRules && !paymentMethodNeededToBeVerify && !paymentMethodCheckError && !AutoPayEnabled && (
          <Box
            position="absolute"
            className={`cursor-pointer text-center ${
              props.isAccountsPayable
                ? `${apTapActionStyle} absolute left-[-57px] top-0 bg-white px-3 py-1 font-poppins`
                : `${tapActionStyle} bg-spenda-warning text-white  hover:bg-yellow-800`
            }`}
            onClick={() => props.onEditPaymentMethod!(props.method, undefined, true)}
            data-autoid="lblSetRules"
          >
            Set Authorisation Rules
          </Box>
        )}
        {paymentMethodCheckError && (
          <Box
            position="absolute"
            className={`${
              props.isAccountsPayable
                ? `${apErrorCheck} absolute top-0 bg-white px-3 py-1 font-poppins`
                : `${tapActionStyle} cursor-pointer bg-spenda-error text-center text-white hover:bg-red-800`
            } `}
            onClick={clickCardAction}
            data-autoid="lblError"
          >
            Check Error
          </Box>
        )}
        {AutoPayEnabled && (
          <Box
            position="absolute"
            className={`text-center text-white ${autoPayStyle} hover:!text-spenda-white absolute top-0 !rounded-[5px] border border-solid !border-[#1C78AD] bg-white px-3 py-1 font-poppins text-[10px] !text-primary hover:bg-[#1C78AD]`}
            data-autoid="lblAutoPay"
          >
            Auto-Pay
          </Box>
        )}
        <Box display="flex" justifyContent="center" paddingTop="0.175rem">
          {props?.newPaymentMethodsStyling ? (
            <PaymentMethodThumbnailIcons pm={props?.method} />
          ) : (
            <SVGIcon
              bankAccount={props.method?.PaymentMethod}
              width={props.isAccountsPayable ? '48px' : undefined}
              height={props.isAccountsPayable ? '48px' : undefined}
            />
          )}
        </Box>
        {!props?.newPaymentMethodsStyling ? (
          <Box lineHeight={1} className={props.isAccountsPayable ? 'pt-3 font-poppins' : 'p-1'}>
            <Box
              display="flex"
              justifyContent="center"
              fontSize={props.isAccountsPayable ? '14px' : '13px'}
              paddingBottom={props.isAccountsPayable ? '5px' : ''}
            >
              <Box paddingRight="4px">BSB</Box>
              <Box fontWeight={600} data-autoid="lblBSB">
                {props.method?.BankBSB}
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" fontSize={props.isAccountsPayable ? '14px' : '13px'}>
              <Box paddingRight="4px">ACC</Box>
              <Box fontWeight={600} data-autoid="lblAccountNumber">
                {props.method?.BankAccountNumber}
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
      {editPaymentIcon()}
      {/* If we've loaded an account, then we know we have "TappedToVerify" */}
      {dialogStage !== PennyVerificationDialogStages.NONE && (
        <PennyVerificationDialog
          loadPaymentMethods={props.loadPaymentMethods}
          bankAccountDetails={account}
          dialogStage={dialogStage}
          setDialogStage={setDialogStage}
          reloadAccount={loadAccount}
          onRemoveAccount={onRemoveMethod}
        ></PennyVerificationDialog>
      )}
    </Box>
  );

  return props.isDeletingMethod ? (
    <LoadingIndicator position={{position: 'relative', top: '2rem'}} size="md" isLoading />
  ) : (
    <Box
      className={`${props.isAccountsPayable ? 'mr-5 pb-8 pt-2' : ''} ${
        props?.newPaymentMethodsStyling ? 'w-[210px]' : ''
      }`}
    >
      {isCreditCard ? cardThumbnail : bankThumbnail}

      <Box
        margin={0}
        display="flex"
        flexDirection="column"
        alignItems="center"
        {...css({maxWidth: `${props.isAccountsPayable ? '100%' : '116px'}`, minWidth: '100px'})}
      >
        <p
          className={`truncate text-center ${matches ? 'w-24' : 'w-full'} ${lgmatches ? 'px-4' : ''} ${
            props.isAccountsPayable ? 'mt-3 w-full font-poppins text-base font-semibold' : ''
          }`}
          data-autoid="lblCardHolderName"
        >
          {props.method?.CardHolderName}
        </p>
        <p
          className={`truncate text-center ${matches ? 'w-24' : 'w-full'} ${lgmatches ? 'px-4' : ''} ${
            props.isAccountsPayable ? 'w-full font-poppins text-base font-semibold' : ''
          }`}
          data-autoid="lblFriendlyName"
        >
          {props.method?.FriendlyName}
        </p>
        {isCreditCard && !props.method?.SupplierPaymentOptionID && !props.isAccountsPayable && (
          <small>(Card won't be saved)</small>
        )}
      </Box>
    </Box>
  );
};
