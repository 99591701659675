import React, {useEffect, useState} from 'react';
import {Button, Textarea, Typography} from 'spenda-ui-react';
import moment from 'moment';

import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {IconPencil} from '../../assets/svg/quotes-management/IconPencil';
import useClaimsAndReturnsAPI from '../../services/useClaimsAndReturnsAPI';
import {IClaimNotes} from '../../model/claims/ClaimsAndReturns';
import LoadingIndicator from '../ui/LoadingIndicator';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import _ from 'lodash';

interface IClaimsInternalNotesDialog {
  handleClose: () => void;
  claimRequestID: number;
  linkedSupplierId?: number;
}

export const ClaimsInternalNotesDialog = (props: IClaimsInternalNotesDialog) => {
  const {handleClose, claimRequestID, linkedSupplierId} = props;
  const [newInternalNote, setNewInternalNote] = useState<string>('');
  const {
    saveBuyerInternalNote,
    saveSupplierInternalNote,
    getBuyerNotesList,
    getSupplierNotesList,
    isLoading,
    updateBuyerInternalNote,
    updateSupplierInternalNote,
    updateBuyerExternalNote,
    updateSupplierExternalNote,
  } = useClaimsAndReturnsAPI();
  const [editNote, setEditNote] = useState<IClaimNotes>();
  const [notesList, setNotesList] = useState<IClaimNotes[]>([]);
  const [newInternalNoteError, setNewInternalNoteError] = useState<boolean>(false);
  const [updatedNote, setUpdatedNote] = useState<string>('');
  const [updatedNoteError, setUpdatedNoteError] = useState<boolean>(false);
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();

  const fetchNotes = async () => {
    const data =
      isInBuyerContext && linkedSupplierId
        ? await getBuyerNotesList(claimRequestID, linkedSupplierId)
        : await getSupplierNotesList(claimRequestID);
    setNotesList(data);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleUpdateNote = async () => {
    if (!editNote || !updatedNote.trim().length) {
      setUpdatedNoteError(true);
      return;
    }
    setUpdatedNoteError(false);
    const payload: IClaimNotes = {
      comment: updatedNote,
      claimRequestID: claimRequestID,
    };
    if (editNote?.claimRequestCommentID) {
      payload.claimRequestCommentID = editNote?.claimRequestCommentID;
      if (isInBuyerContext && linkedSupplierId) await updateBuyerInternalNote(payload, linkedSupplierId);
      else if (isInSupplierContext) updateSupplierInternalNote(payload);
    } else if (editNote?.claimRequestLineID) {
      payload.claimRequestLineID = editNote?.claimRequestLineID;
      if (editNote?.claimRequestAttachmentID && editNote?.claimRequestAttachmentID != null) {
        payload.claimRequestAttachmentID = editNote?.claimRequestAttachmentID;
      }
      if (isInBuyerContext && linkedSupplierId) await updateBuyerExternalNote(payload, linkedSupplierId);
      else await updateSupplierExternalNote(payload);
    }
    setUpdatedNote('');
    setEditNote(undefined);
    fetchNotes();
  };

  const handleSaveNote = async () => {
    if (!newInternalNote.trim().length) {
      setNewInternalNoteError(true);
      return;
    }
    setNewInternalNoteError(false);
    if (isInBuyerContext && linkedSupplierId)
      await saveBuyerInternalNote(newInternalNote, claimRequestID, linkedSupplierId);
    else if (isInSupplierContext) await saveSupplierInternalNote(newInternalNote, claimRequestID);
    setNewInternalNote('');
    fetchNotes();
  };

  return (
    <AlertDialogSlideV2
      title={'Notes history'}
      headingTextSize="h2"
      headingClassess="text-black justify-center"
      footerClass="justify-center sm:w-auto w-full"
      dialogClassess="!min-w-[20%] !max-w-[940px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
      dialogBodyClassess="flex justify-center px-0"
      data-autoid={'dlgAddInternalNote'}
      actions={[
        {
          label: 'Close',
          variant: 'outlined',
          action: handleClose,
        },
      ]}
    >
      <div className="w-full">
        <div className="mb-6 flex w-full items-end justify-between gap-5 px-10 pt-3">
          <div className="w-full">
            <Typography variant="h3" className="mb-4 text-spenda-greytext">
              Add internal note
            </Typography>
            <Textarea
              label="Add new note"
              displayLength
              maxLength={500}
              data-autoid={'txtInternalNote'}
              value={newInternalNote}
              helperText="Required"
              error={newInternalNoteError}
              onChange={e => {
                setNewInternalNoteError(false);
                setNewInternalNote(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              className="mb-[7px] min-w-[112px] px-2"
              variant="outlined"
              onClick={handleSaveNote}
              disabled={isLoading}
            >
              Save Note
            </Button>
          </div>
        </div>
        <hr className="mb-7 border-[#ececec]" />
        <div className="flex max-h-[300px] w-full items-start justify-between gap-5 overflow-auto px-10">
          <div className="min-h-[100px] w-full">
            <Typography variant="h3" className="mb-4 text-spenda-greytext">
              Note history
            </Typography>
            {isLoading ? (
              <LoadingIndicator isLoading={true} size="md" position={{right: '49%', top: '80%'}} />
            ) : (
              <>
                {notesList?.map((note, index) => {
                  const isEditNote = _.isEqual(note, editNote);
                  return (
                    <div
                      key={index}
                      className={`${isEditNote ? 'pb-5 pt-3.5' : 'py-3.5'} mb-4 flex w-full items-start justify-between rounded-lg bg-primary/5 px-5`}
                    >
                      <div className="flex w-3/4 flex-col gap-y-2 text-black-800">
                        {isEditNote ? (
                          <Textarea
                            label="Edit internal note"
                            displayLength
                            maxLength={500}
                            data-autoid={'txtInternalNote'}
                            value={updatedNote}
                            helperText="Required"
                            error={updatedNoteError}
                            onChange={e => {
                              setUpdatedNoteError(false);
                              setUpdatedNote(e.target.value);
                            }}
                          />
                        ) : (
                          <>
                            {note?.claimRequestLineID && (
                              <Typography variant="small">
                                <span className="font-medium">Line item:</span>{' '}
                                {`${note?.code} ${note?.claimRequestAttachmentID && note?.claimRequestAttachmentID != null ? 'Image attached' : ''}`}
                              </Typography>
                            )}
                            <Typography variant="small">
                              <span className="font-medium">
                                {note?.claimRequestLineID ? ' External note:' : 'Internal note:'}
                              </span>{' '}
                              {note?.comment}
                            </Typography>
                            <div className="flex items-center">
                              <Typography variant="small">
                                <span className="font-medium">Date created:</span>{' '}
                                {note?.createdDateTime_utc && moment(note?.createdDateTime_utc).format('DD-MMM-YYYY')}
                              </Typography>
                              <div className="mx-10 h-3 w-[2px] bg-black-800"></div>
                              <Typography variant="small">
                                <span className="font-medium">Created by:</span> {note?.createdByUserName}
                              </Typography>
                            </div>
                            <Typography variant="xsmall" className="text-spenda-labeltext">
                              Modified by{' '}
                              <span className="font-medium">{note?.modifiedByUserName || note?.createdByUserName}</span>{' '}
                              on{' '}
                              {note?.modifiedDateTime_utc
                                ? moment(note?.modifiedDateTime_utc).format('DD-MMM-YYYY')
                                : note?.createdDateTime_utc
                                  ? moment(note?.createdDateTime_utc).format('DD-MMM-YYYY')
                                  : ''}
                            </Typography>
                          </>
                        )}
                      </div>
                      <div className="cursor-pointer">
                        <Button
                          ripple={false}
                          color="primary"
                          variant="text"
                          className="flex items-start justify-start px-0 text-sm"
                          onClick={() => {
                            if (isEditNote) handleUpdateNote();
                            else {
                              setEditNote(note);
                              setUpdatedNote(note?.comment);
                            }
                          }}
                        >
                          {isEditNote ? (
                            'Save'
                          ) : (
                            <div className="flex items-center justify-start gap-1">
                              <IconPencil />
                              <Typography variant="small" className="font-semibold text-primary">
                                Edit
                              </Typography>
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="min-w-[112px]"></div>
        </div>
      </div>
    </AlertDialogSlideV2>
  );
};

export default ClaimsInternalNotesDialog;
