import React from 'react';

export const IconBlueInfo = (props: SVGProps) => (
  <svg width="16px" height="16px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Spenda-Pay-Web" strokeWidth="1" className="fill-primary" fillRule="evenodd">
      <g id="Landing-Screen/Create-Batch-Copy-2" transform="translate(-1381.000000, -515.000000)">
        <g id="Icons/General/info" transform="translate(1381.000000, 515.000000)">
          <path
            d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C15.9952234,3.58370203 12.416298,0.00477656508 8,0 Z M8,14.6666667 C4.31810167,14.6666667 1.33333333,11.6818983 1.33333333,8 C1.33333333,4.31810167 4.31810167,1.33333333 8,1.33333333 C11.6818983,1.33333333 14.6666667,4.31810167 14.6666667,8 C14.6626251,11.680223 11.680223,14.6626251 8,14.6666667 L8,14.6666667 Z"
            id="Shape"
          ></path>
          <path
            d="M8,6.66666667 L7.33333333,6.66666667 C6.9651435,6.66666667 6.66666667,6.9651435 6.66666667,7.33333333 C6.66666667,7.70152317 6.9651435,8 7.33333333,8 L8,8 L8,12 C8,12.3681898 8.29847683,12.6666667 8.66666667,12.6666667 C9.0348565,12.6666667 9.33333333,12.3681898 9.33333333,12 L9.33333333,8 C9.33333333,7.26362033 8.73637967,6.66666667 8,6.66666667 Z"
            id="Path"
          ></path>
          <circle id="Oval" cx="8" cy="4.33333333" r="1"></circle>
        </g>
      </g>
    </g>
  </svg>
);
