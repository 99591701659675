import React, {useContext, useMemo} from 'react';
import {IconButton, Typography} from 'spenda-ui-react';

import AppContext from '../../context/app/appContext';
import {ProfitMarginTooltip} from './ProfitMarginTooltip';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {IServiceJob} from '../../model/service-management/serviceJob';
import {IQuotes} from '../../model/quotes/quotes';
import {PriceFormat} from '../../utils/formatter';
import PriceCalculator from '../../utils/priceCalculator';
import {LockIcon, UnLockIcon} from '../../assets/svg';

interface CalculationTableProps<T> {
  values: T;
  setFieldValue: (field: string, value: any) => void;
  handleDiscountChange: (value: string, type: DISCOUNT_MODE) => void;
  handleShippingChange: (value: string) => void;
  serviceJob?: boolean;
  handleUnlock?: (index: number) => void;
  isDiscountEditable?: boolean;
  isShippingEditable?: boolean;
  isServiceJobFromQuote?: boolean;
  disabled?: boolean;
}

/**
 * Calculation Table Component
 * @param {T} props - CalculationTableProps <T> extends IServiceJob | IQuotes
 * @returns {JSX.Element}
 */
export const CalculationTable = <T extends IServiceJob | IQuotes>(props: CalculationTableProps<T>) => {
  const {
    values,
    setFieldValue,
    handleDiscountChange,
    handleShippingChange,
    serviceJob,
    isDiscountEditable,
    isShippingEditable,
    isServiceJobFromQuote,
    handleUnlock,
    disabled,
  } = props;

  const {tenantInfo} = useContext(AppContext);

  const calculatedData = useMemo(() => {
    let _subTotal = 0;
    let _totalCostPriceEx = 0;
    let _totalSalesPriceEx = 0;
    let _unDiscountedTotalInc = 0;
    let _discountEx = 0;
    let _discountIndex = -1;
    let _shippingIndex = -1;
    for (let i = 0; i < values?.lines.length; i++) {
      if (
        values?.lines[i].inventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        values?.lines[i].inventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      ) {
        _totalCostPriceEx += values?.lines[i].quantity * (values?.lines[i].costPriceEx ?? 0);
        _subTotal += Number(values?.lines[i]?.lineTotalEx) || 0;
        _totalSalesPriceEx += values?.lines[i].quantity * Number(values?.lines[i]?.sellPriceEx) ?? 0;
        _unDiscountedTotalInc += values?.lines[i].lineTotalInc || 0;
      } else if (values?.lines[i].inventoryID === tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId) {
        _discountEx = values?.lines[i].lineTotalEx || 0;
        _discountIndex = i;
      } else if (values?.lines[i].inventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId) {
        _shippingIndex = i;
      }
    }
    return {
      subTotal: _subTotal,
      totalCostPriceEx: _totalCostPriceEx,
      totalSalesPriceEx: _totalSalesPriceEx,
      unDiscountedTotalInc: _unDiscountedTotalInc,
      discountEx: _discountEx,
      discountIndex: _discountIndex,
      shippingIndex: _shippingIndex,
    };
  }, [JSON.stringify(values?.lines)]);

  const {subTotal, totalCostPriceEx, totalSalesPriceEx, unDiscountedTotalInc, discountEx} = calculatedData;

  const LockButton = ({index, isEditable}: {index: number; isEditable?: boolean}) => {
    return (
      <>
        {serviceJob && isServiceJobFromQuote ? (
          !isEditable ? (
            <IconButton
              variant="text"
              name="DiscountLock"
              onClick={() => handleUnlock?.(index)}
              disabled={disabled}
              className="active:bg-transparent"
              ripple={false}
            >
              <LockIcon />
            </IconButton>
          ) : (
            <div className="flex w-10 justify-center">
              '<UnLockIcon />
            </div>
          )
        ) : (
          <span className="block w-10"></span>
        )}
      </>
    );
  };

  return (
    <table className="w-full font-poppins">
      <tbody className="text-sm">
        <tr>
          <td rowSpan={6} width={serviceJob ? '69%' : '79%'}></td>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <div className="flex items-center justify-end gap-x-3 bg-[#FAFAFA] p-2.5 ">
              {!serviceJob && totalSalesPriceEx > 0 && (
                <ProfitMarginTooltip
                  profitMargin={totalSalesPriceEx - totalCostPriceEx + Number(discountEx || 0)}
                  profitMarginPercentage={
                    ((totalSalesPriceEx - totalCostPriceEx + Number(discountEx || 0)) / totalCostPriceEx) * 100
                  }
                />
              )}
              <Typography className="text-right text-black-800" variant="small">
                Sub Total
              </Typography>
            </div>
          </td>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography className="p-2.5 text-right text-black-800" data-autoid={'txtSubTotal'} variant="small">
              {PriceFormat(subTotal, '$', 2)}
            </Typography>
          </td>
          <td width={serviceJob ? '1%' : '40px'} className="border-y border-[#EAEAEA]"></td>
        </tr>
        <tr>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
              Discount (Inc)
            </Typography>
          </td>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA] py-0">
            <div className="flex justify-end gap-x-[1px]">
              <div className="flex w-[60px] max-w-[60px] items-center border border-[#707070] py-2.5">
                <input
                  placeholder="0.00"
                  type="number"
                  data-autoid="txtDiscountPercent"
                  pattern="^[0-9][0-9]?$|^100$"
                  className="text-s w-3/4 text-right font-normal text-black-800"
                  value={
                    values?.discountMode === DISCOUNT_MODE.PERCENT
                      ? values?.discountString
                      : PriceCalculator.roundNumber((Number(values?.discount) / unDiscountedTotalInc!) * 100)
                  }
                  disabled={(serviceJob && !isDiscountEditable) || disabled}
                  onChange={e => {
                    if (e.target.value.match(/\./g)) {
                      const [, decimal] = e.target.value.split('.');

                      // restrict value to only 2 decimal places
                      if (decimal?.length > 2) {
                        // do nothing
                        return;
                      }
                    }
                    const {value} = e.target;
                    if (Number(value) >= 0 && Number(value) <= 100 && /^\d+(\.\d{0,2})?$|^$/.test(value)) {
                      handleDiscountChange(value, DISCOUNT_MODE.PERCENT);
                    }
                  }}
                />
                %
              </div>
              <div className="flex w-[60px] max-w-[60px] items-center justify-center border border-[#707070] py-2.5">
                $
                <input
                  placeholder="0.00"
                  data-autoid="txtDiscountAmt"
                  type="number"
                  className="w-[60%] text-right text-sm font-normal  text-black-800"
                  value={
                    values?.discountMode === DISCOUNT_MODE.DOLLAR
                      ? values?.discountString
                      : PriceCalculator.roundNumber((Number(values?.discountString) * unDiscountedTotalInc) / 100)
                  }
                  disabled={(serviceJob && !isDiscountEditable) || disabled}
                  onChange={e => handleDiscountChange(e.target.value, DISCOUNT_MODE.DOLLAR)}
                />
              </div>
            </div>
          </td>
          <td width={serviceJob ? '1%' : '1'} className="border-y border-[#EAEAEA]">
            <LockButton index={calculatedData.discountIndex} isEditable={isDiscountEditable} />
          </td>
        </tr>
        <tr>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
              Shipping (Inc)
            </Typography>
          </td>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA] py-0 pr-0">
            <div className="flex justify-end">
              <div className="flex max-w-[60px] items-center justify-center border border-[#707070] py-2.5">
                $
                <input
                  placeholder="0.00"
                  data-autoid="txtShippingAmt"
                  type="number"
                  className="w-[60%] text-right text-sm font-normal  text-black-800"
                  value={values?.shipping}
                  disabled={(serviceJob && !isShippingEditable) || disabled}
                  onChange={e => {
                    if (e.target.value.match(/\./g)) {
                      const [, decimal] = e.target.value.split('.');

                      // restrict value to only 2 decimal places
                      if (decimal?.length > 2) {
                        // do nothing
                        return;
                      }
                    }
                    setFieldValue('shipping', e.target.value);
                    if (unDiscountedTotalInc) handleShippingChange(e.target.value);
                  }}
                />
              </div>
            </div>
          </td>
          <td width={serviceJob ? '1%' : '40px'}>
            <LockButton index={calculatedData.shippingIndex} isEditable={isShippingEditable} />
          </td>
        </tr>
        <tr>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
              Tax
            </Typography>
          </td>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography data-autoid={`txtTotalTax`} className="p-2.5 text-right text-black-800" variant="small">
              {`${PriceFormat(values?.totalTax || 0)}`}
            </Typography>
          </td>
          <td width={serviceJob ? '1%' : '40'} className="border-y border-[#EAEAEA]"></td>
        </tr>
        <tr>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography className="bg-[#FAFAFA] p-2.5 text-right text-black-800" variant="small">
              Total
            </Typography>
          </td>
          <td width={serviceJob ? '15%' : '10%'} className="border-y border-[#EAEAEA]">
            <Typography className="p-2.5 text-right text-black-800" data-autoid={`txtTotalInc`} variant="small">
              {`${PriceFormat(values?.totalInc || 0)}`}
            </Typography>
          </td>
          <td width={'1%'} className="border-y border-[#EAEAEA]">
            <span className="block w-10"></span>
          </td>
          {serviceJob && (
            <td width={'1%'}>
              <span className="block w-10"></span>
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
};
