import {ApplicationInfo, CapricornApplicationInfo} from '../model/constants/ApplicationInfo';
import {ITheme} from '../model/theme/Theme';
import CapricornLMITheme from '../themes/capricorn/CapricornLMITheme';
import CapricornDSDTheme from '../themes/capricorn/CapricornDSDTheme';

interface IEnvironmentConfig {
  /** Spenda API URL */
  API_URL: string;
  /** Auth0 - Audience */
  AUTH_AUDIENCE: string;
  /** Auth0 - Connection */
  AUTH_CONNECTION?: string;
  /** Auth0 - Application Client ID */
  AUTH_CLIENT_ID: string;
  /** Auth0 - Domain */
  AUTH_DOMAIN: string;
  /** Environment name (feature used as catch-all to just determine using HOST_NAME) */
  ENV_NAME: string;
  /** Host name - used with .includes() to match against window.location.host */
  HOST_NAME: string;

  FEAT_NAME: string;
  INSTRUMENTATION_KEY: string;
  LD_API_ACCESS_TOKEN?: string;
  LD_CLIENT_ID?: string;
  THEME?: ITheme;
  WEBSITE_ID?: number;
  MARKETPLACE_PATH?: string;
  LIMEPAY_KEY?: string;
}

const environmentConfigs: IEnvironmentConfig[] = [
  {
    ENV_NAME: 'local',
    HOST_NAME: 'localhost:3001',
    API_URL: 'http://localhost:8088/',
    INSTRUMENTATION_KEY: 'f769aff1-33f6-4e63-bcc7-14967dae59cb',
    AUTH_CLIENT_ID: 'mlO7UF7rcFYp5wezzemJI8eA5ObEk1RY',
    AUTH_DOMAIN: 'auth.test.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-test-api',
    FEAT_NAME: 'local',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
  },
  {
    ENV_NAME: 'local',
    HOST_NAME: 'localhost:3000',
    API_URL: 'http://localhost:8088/',
    INSTRUMENTATION_KEY: 'f769aff1-33f6-4e63-bcc7-14967dae59cb',
    AUTH_CLIENT_ID: 'oosbNbpBYZML9qsBUzl32hLm5nBRCSaa',
    AUTH_DOMAIN: 'auth.test.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-test-api',
    FEAT_NAME: 'local',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
  },
  {
    ENV_NAME: 'localproxy',
    HOST_NAME: 'localhost:3001',
    API_URL: 'http://localhost:8088/',
    INSTRUMENTATION_KEY: 'f769aff1-33f6-4e63-bcc7-14967dae59cb',
    AUTH_CLIENT_ID: '8Pq7La0kYutBFZhqO1eFuZnWg63j0dIO',
    AUTH_DOMAIN: 'spenda-localdev.au.auth0.com',
    AUTH_AUDIENCE: 'https://localdev-spenda-api',
    FEAT_NAME: 'localproxy',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
  },
  {
    ENV_NAME: 'production',
    HOST_NAME: 'my.spenda.co',
    API_URL: 'https://live.api.spenda.co/',
    INSTRUMENTATION_KEY: 'd704a930-fe20-4933-8ab6-a1e003c8e311',
    AUTH_CLIENT_ID: '3eEWpaaujRkV1ySi5kUvVUqerJWHA360',
    AUTH_DOMAIN: 'auth.api.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-api',
    FEAT_NAME: 'production',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2d',
  },
  {
    ENV_NAME: 'production',
    HOST_NAME: 'statement-integration.spenda.co',
    API_URL: 'https://live.api.spenda.co/',
    INSTRUMENTATION_KEY: 'd704a930-fe20-4933-8ab6-a1e003c8e311',
    AUTH_CLIENT_ID: 'lRiBCxT6R3I0PMNtHehe2ZbchBw6pqbD',
    AUTH_DOMAIN: 'auth.api.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-api',
    FEAT_NAME: 'capproduction',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2d',
    AUTH_CONNECTION: 'mycap',
    THEME: CapricornLMITheme,
    WEBSITE_ID: CapricornApplicationInfo.WebsiteId,
    MARKETPLACE_PATH: 'capricorn',
  },
  {
    ENV_NAME: 'sandbox',
    HOST_NAME: process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'spenda.sandbox.spenda.co',
    API_URL: 'https://api.sandbox.spenda.co/',
    INSTRUMENTATION_KEY: 'bf10651c-7878-446c-b4b5-a20f08b7acc8',
    AUTH_CLIENT_ID: 'xZuHLe3obNeYCr6rIe3Z1ychApo0XGOv',
    AUTH_DOMAIN: 'auth.sandbox.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-sandbox-api',
    FEAT_NAME: 'sandbox',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    WEBSITE_ID: ApplicationInfo.WebsiteId,
    LIMEPAY_KEY: 'sandbox_pk_ODNjMzI0ZDUtNDIwMi00N2QzLTliNzQtZTg5ZGQ4MzQ4Y2U1',
  },
  {
    ENV_NAME: 'sandbox',
    HOST_NAME: process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'statement-integration.sandbox.spenda.co',
    API_URL: 'https://api.sandbox.spenda.co/',
    INSTRUMENTATION_KEY: 'bf10651c-7878-446c-b4b5-a20f08b7acc8',
    AUTH_CLIENT_ID: 'yFZVBy16EEs53ou9euNMN4iRq6q86XBU',
    AUTH_DOMAIN: 'auth.sandbox.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-sandbox-api',
    FEAT_NAME: 'capsandbox',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    AUTH_CONNECTION: 'mycap-sandbox',
    THEME: CapricornLMITheme,
    WEBSITE_ID: CapricornApplicationInfo.WebsiteId,
    MARKETPLACE_PATH: 'captest1',
  },
  {
    ENV_NAME: 'devtest',
    HOST_NAME: process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'spenda.devtest.spenda.co',
    API_URL: 'https://api.devtest.spenda.co/',
    INSTRUMENTATION_KEY: 'f1d3237c-7fee-411f-9458-e6e87c934d68',
    AUTH_CLIENT_ID: 'oosbNbpBYZML9qsBUzl32hLm5nBRCSaa',
    AUTH_DOMAIN: 'auth.devtest.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-devtest-api',
    FEAT_NAME: 'devtest',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    THEME: CapricornDSDTheme,
  },
  {
    ENV_NAME: 'devtest',
    HOST_NAME: process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'statement-integration.devtest.spenda.co',
    API_URL: 'https://api.devtest.spenda.co/',
    INSTRUMENTATION_KEY: 'f1d3237c-7fee-411f-9458-e6e87c934d68',
    AUTH_CLIENT_ID: '92UHKOY9F0mTZ6xBYhjNY11CnFDMJQUW',
    AUTH_DOMAIN: 'auth.devtest.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-devtest-api',
    FEAT_NAME: 'capdevtest',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    AUTH_CONNECTION: 'mycap-devtest',
    THEME: CapricornLMITheme,
    WEBSITE_ID: CapricornApplicationInfo.WebsiteId,
    MARKETPLACE_PATH: 'Capricorn',
  },
  {
    ENV_NAME: 'perf',
    HOST_NAME: process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'spenda.perf.spenda.co',
    API_URL: 'https://api.perf.spenda.co/',
    INSTRUMENTATION_KEY: 'f1d3237c-7fee-411f-9458-e6e87c934d68',
    AUTH_CLIENT_ID: 'u91j5LW97TbwJfIti6AVMPo28JOF0I6I',
    AUTH_DOMAIN: 'auth.perf.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-perf-api',
    FEAT_NAME: 'perf',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2d',
    THEME: CapricornDSDTheme,
  },
  {
    ENV_NAME: 'perf',
    HOST_NAME: process.env.NODE_ENV === 'development' ? 'localhost:3000' : 'statement-integration.perf.spenda.co',
    API_URL: 'https://api.perf.spenda.co/',
    INSTRUMENTATION_KEY: 'f1d3237c-7fee-411f-9458-e6e87c934d68',
    AUTH_CLIENT_ID: '970hrlJfNNCCptfkZb4NmRDNtslIHRDJ',
    AUTH_DOMAIN: 'auth.perf.spenda.co',
    AUTH_AUDIENCE: 'https://spenda-perf-api',
    FEAT_NAME: 'capperf',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2d',
    AUTH_CONNECTION: 'mycap-perf',
    THEME: CapricornLMITheme,
    WEBSITE_ID: CapricornApplicationInfo.WebsiteId,
    MARKETPLACE_PATH: 'Capricorn',
  },
  {
    ENV_NAME: 'dev1',
    HOST_NAME: 'checkout-admin.ecomm-dev1.capricorn.coop',
    API_URL: 'https://checkout-api.ecomm-dev1.capricorn.coop',
    INSTRUMENTATION_KEY: '5b5d3afd-91f4-414b-a845-d907716a37ec',
    AUTH_CLIENT_ID: 'Lb4gVqiHvUHngqSHXi7TvzHxx51qNUgL',
    AUTH_DOMAIN: 'checkout-auth.ecomm-dev1.capricorn.coop',
    AUTH_AUDIENCE: 'https://capricorn-ecomm-dev1-checkout-api',
    FEAT_NAME: 'dev1',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    THEME: CapricornDSDTheme,
    MARKETPLACE_PATH: 'capricorn',
  },
  {
    ENV_NAME: 'tst1',
    HOST_NAME: 'checkout-admin.ecomm-tst1.capricorn.coop',
    API_URL: 'https://checkout-api.ecomm-tst1.capricorn.coop',
    INSTRUMENTATION_KEY: 'cb53f85a-4311-4408-a759-9fd6156480ee',
    AUTH_CLIENT_ID: '8nN2XAtwlSNHNZuRkoFHVI70G1v41c3m',
    AUTH_DOMAIN: 'checkout-auth.ecomm-tst1.capricorn.coop',
    AUTH_AUDIENCE: 'https://capricorn-ecomm-tst1-checkout-api',
    FEAT_NAME: 'tst1',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    THEME: CapricornDSDTheme,
    MARKETPLACE_PATH: 'capricorn',
  },
  {
    ENV_NAME: 'stg1',
    HOST_NAME: 'checkout-admin.ecomm-stg1.capricorn.coop',
    API_URL: 'https://checkout-api.ecomm-stg1.capricorn.coop',
    INSTRUMENTATION_KEY: '022b1983-f6eb-42ba-ab9e-eada79ff0157',
    AUTH_CLIENT_ID: 'WPbbchMQ8p05YbloPjMdHJw7GE58L524',
    AUTH_DOMAIN: 'checkout-auth.ecomm-stg1.capricorn.coop',
    AUTH_AUDIENCE: 'https://capricorn-ecomm-stg1-checkout-api',
    FEAT_NAME: 'stg1',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    THEME: CapricornDSDTheme,
    MARKETPLACE_PATH: 'capricorn',
  },
  {
    ENV_NAME: 'sbx',
    HOST_NAME: 'checkout-admin-sbx.capricorn.coop',
    API_URL: 'https://checkout-api-sbx.capricorn.coop',
    INSTRUMENTATION_KEY: 'eccf7803-0af8-400e-bcd4-4cb13447d36f',
    AUTH_CLIENT_ID: 'zSWTmaZ18i9qBbro8jqmjNK93Cxn9mX0',
    AUTH_DOMAIN: 'checkout-auth-sbx.capricorn.coop',
    AUTH_AUDIENCE: 'https://capricorn-ecomm-sbx-checkout-api',
    FEAT_NAME: 'sbx',
    LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2c',
    THEME: CapricornDSDTheme,
    MARKETPLACE_PATH: 'capricorn',
  },
  // {
  //   ENV_NAME: 'prd',
  //   HOST_NAME: 'checkout-admin.capricorn.coop',
  //   API_URL: 'https://checkout-api.capricorn.coop',
  //   INSTRUMENTATION_KEY: '<to update>',
  //   AUTH_CLIENT_ID: '<to update>',
  //   AUTH_DOMAIN: 'checkout-auth.capricorn.coop',
  //   AUTH_AUDIENCE: 'https://capricorn-ecomm-checkout-api',
  //   FEAT_NAME: 'prd',
  //   LD_CLIENT_ID: '61a6edc4846cbc11ad61bc2d',
  //   THEME: CapricornTheme,
  //   WEBSITE_ID: CapricornApplicationInfo.WebsiteId,
  //   MARKETPLACE_PATH: 'capricorn',
  // }
];

const currentHostname = window.location.host.toLowerCase();
const currentEnvironment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
const currentFeature = process.env.REACT_APP_FEATURE?.toLowerCase();
/**
 * Returns the correct environment configuration based on request host and environment
 *
 * @param host - hostname of request - eg. window.location.host
 * @param env - environment - to be used locally or in builds (is null when app is deployed)
 * @returns IEnvironmentConfig - the environment configuration values
 *
 */
function getConfig(host: string, env?: string, feat?: string): IEnvironmentConfig {
  return (
    environmentConfigs.find(
      config =>
        host.includes(config.HOST_NAME.toLowerCase()) &&
        (!env || env == 'feature' || env == config.ENV_NAME.toLowerCase()) &&
        (!feat || feat == config.FEAT_NAME.toLowerCase()),
    ) || environmentConfigs[0]
  );
}

const config = getConfig(currentHostname, currentEnvironment, currentFeature);
export default config;
