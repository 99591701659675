import React, {PropsWithChildren, useContext, useState} from 'react';
import {css} from 'glamor';
import {IMenuOption} from '../../model/tenant/TenantModuleDetails';
import {Link, useHistory} from 'react-router-dom';
import SVGIcon, {SVGName} from '../ui/SVGIcon';
import {MySpendaNewTheme} from '../../assets/svg/MySpendaNewTheme';
import {SupportChat} from './SupportChat';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import useConnectedSupplier from '../../hooks/useConnectedSupplier';
import {makeStyles} from '@material-ui/core';
import {ITenant, LogoTypeEnum} from '../../model/Tenant';
import {NameInitials} from '../../utils/formatter';
import {SPopover} from '../modals/modalSpendaMeterialUI';
import {ITenantInfo} from '../../model/Tenant';
import {ModuleTypes} from '../../model/constants/Constants';
import {IUserMenu} from '../../services/MenuService';
import {IUser} from '../../model/user/User';
import {ZohoContext} from '../../context/app/ZohoContext';
import {MenuDialog} from '../menu/Menu';
import {IMenuModule} from '../../model/menu/Menu';
import useTheme from '../../context/theme-context/useTheme';
import CapricornLogo from '../../assets/png/CapricornLogo.png';

const speechBubbleModal = css({
  background: 'linear-gradient(180deg, rgb(0, 122, 159) 0%, rgb(0, 45, 79) 100%)',
  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.41), -2px -1px 9px 3px rgba(0, 0, 0, 0.37)',
  position: 'relative',
  border: '2px solid #FFF',
  top: '11px',
  '@media(min-width: 1280px)': {
    // TODO: V1.1 when ActionCentre implemented
    // width: "1000px",
    width: '500px',
  },
  // TODO: Need to come back to this Resolution fix with Hooks
  '@media(min-width: 1024px)': {
    width: '500px',
  },
  '&:after': {
    height: '16px',
    width: '16px',
    position: 'absolute',
    content: "''",
    background: '#00789d',
    border: '2px solid #FFF',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    transform: 'rotate(45deg)',
    top: '-9px',
    left: '25px',
  },
});

const backgroud = css({
  backgroundColor: 'rgb(63 125 151) !important',
});

export interface IModuleList {
  title: string;
  modules?: Partial<IMenuOption>[];
  suppliers?: IConnectedSupplier[];
}

export interface IModuleBrowserProps {
  user: IUser;
  menu: IUserMenu;
  tenantInfo: ITenantInfo;
  suppliers: IConnectedSupplier[];
  supplierId?: string;
  customerId?: string;
  transactionId?: string;
  pathname: string;
  tenant: ITenant;
  modules: IMenuModule[];
}

export const ModuleBrowser = (props: IModuleBrowserProps) => {
  const {user, suppliers, menu, tenantInfo, supplierId, customerId, transactionId, pathname, tenant, modules} = props;

  // const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const useStyles = makeStyles({
    root: {
      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
        overflow: 'hidden',
        top: '91px !important',
        left: '20px !important',
      },
    },
  });

  const classes = useStyles();
  const history = useHistory();
  const {logo} = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleClick = () => {
    history.push('/menu');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const menuList: IModuleList[] = [
    {title: 'Selling', modules: menu?.Selling.MenuItems},
    {
      title: 'Buying',
      modules: menu?.Buying.MenuItems,
      suppliers: suppliers,
    },
  ];

  const zohoChatContext = useContext(ZohoContext);

  return (
    <>
      {!supplierId && (
        <div className={`z-50 grid cursor-pointer pl-2 pt-1`}>
          <button className={`flex focus:outline-none`} onClick={handleClick} data-autoid="btnMySpenda">
            {logo ? (
              <div className="rounded bg-gray-200 p-4">
                <img src={CapricornLogo} alt="Capricorn" className="w-16" />
              </div>
            ) : (
              <MySpendaNewTheme />
            )}
          </button>
        </div>
      )}
      <MenuDialog
        openMenu={showMenu}
        handleClose={handleCloseMenu}
        user={user}
        tenant={tenant}
        supplierId={supplierId}
        modules={modules}
      />
      <SPopover
        classes={{root: classes.root}}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <div>
          <div className={`rounded border-2 p-2 text-white ${speechBubbleModal}`}>
            <h1 className="text-left">{user?.TenantName ? user.TenantName : `${user?.FirstName} ${user?.LastName}`}</h1>
            <div className="flex border-t-2 border-white py-4">
              <div className="flex w-3/6 flex-1 flex-col border-white">
                {menuList.map((ml: IModuleList, id: number) => {
                  return (
                    <ModuleList
                      key={`${ml.title}-module-${id}`}
                      title={ml.title}
                      menuItems={ml.modules}
                      handleClose={handleClose}
                      suppliers={ml.suppliers}
                      selectedModule={pathname?.toLowerCase()}
                      selectedSupplier={supplierId}
                      tenantInfo={tenantInfo}
                      customerId={customerId}
                      transactionId={transactionId}
                    />
                  );
                })}
                {zohoChatContext && <SupportChat user={user} />}
              </div>
            </div>
          </div>
        </div>
      </SPopover>
    </>
  );
};

const ModuleList = (
  props: PropsWithChildren<{
    title: string;
    // modules?: Partial<ITenantModuleDetails>[] | undefined,
    menuItems?: Partial<IMenuOption>[] | undefined;
    suppliers?: IConnectedSupplier[];
    selectedModule?: string;
    selectedSupplier?: string;
    handleClose: () => void;
    tenantInfo: ITenantInfo | undefined;
    customerId?: string;
    transactionId?: string;
  }>,
) => {
  const {menuItems: modules, suppliers, handleClose, tenantInfo} = props;

  const {getRedirectPath} = useConnectedSupplier();
  const overflow = css({
    overflowY: 'hidden',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  });

  const getDaysLeft = (ModuleDependencies: number[]) => {
    const module =
      Array.isArray(tenantInfo?.Modules) &&
      tenantInfo?.Modules.find(module => {
        return [module?.ModuleID].some(m => ModuleDependencies.indexOf(m) >= 0);
      });

    if (module) {
      if (module?.IsActive && module.Status === 'TrialActive') {
        return (module && module?.TrialRemainingDays?.toString()) || '';
      }

      if (module.IsExpired && module.Status === 'TrialExpired') {
        return 'EXPIRED';
      }
      return '';
    } else {
      return '';
    }
  };

  const getTrySpendaCollectLabel = () => {
    const module =
      Array.isArray(tenantInfo?.Modules) &&
      tenantInfo?.Modules.find(module => {
        return module.ModuleID === ModuleTypes.SpendaCollectAR;
      });

    if (module && module.IsTenantOwned) {
      return 'Complete Setup Now';
    }

    return 'Try Collect Now';
  };
  const SpendaCollectModule = tenantInfo?.Modules.find(m => m.ModuleID === ModuleTypes.SpendaCollectAR);
  return (
    <div className="pb-4 last:pb-0">
      <h3 className="text-left">{props.title}</h3>
      <div
        className={`rounded bg-gray-100 bg-opacity-25 text-center ${backgroud} ${modules?.length ? '' : 'h-25'}`}
        style={{padding: '10px 0'}}
      >
        <div className={`flex items-center justify-center`}>
          {modules?.length ? (
            modules.map((m, id) => {
              let showDaysLeft = '';
              if (m && m?.ModuleDependencies) {
                showDaysLeft = getDaysLeft(m?.ModuleDependencies);
              }

              // Is Hidden
              if (m.isHidden) {
                return null;
              }

              return (
                <MenuItem
                  key={`${m.Name}-${id}`}
                  onClick={props.handleClose}
                  label={m.Name}
                  id={m.ID}
                  isActive={props?.selectedModule?.includes((m.RouteToMatch?.toLowerCase() || m.Route?.toLowerCase())!)}
                  linkTo={m.Route}
                  locationUrl="string"
                  showDaysLeft={showDaysLeft}
                  menuType={props.title === 'Selling' ? 'Selling' : 'Buying'}
                  icon={m.Src}
                />
              );
            })
          ) : (
            <p style={{height: '100px', lineHeight: '100px'}}>
              {/* {!(user && user.DefaultSupplierMarketplace) &&
                `You don't have any modules`} */}
            </p>
          )}

          {props.title === 'Selling' && SpendaCollectModule && !SpendaCollectModule.IsModuleSetup && (
            <MenuItem
              key={`spendaBuyer`}
              onClick={props.handleClose}
              label={getTrySpendaCollectLabel()}
              id={1}
              linkTo={'/onboarding'}
              locationUrl="string"
              menuType="Selling"
              icon={SVGName.Collect}
            />
          )}
        </div>
        {props.title === 'Buying' && (
          <div className="px-4 py-2">
            <h3 className="mb-4 mt-2 w-full border-b-2 text-center" style={{lineHeight: '0.1rem'}}>
              <span className="px-2" style={{backgroundColor: '#3e7d97'}}>
                Connected Suppliers
              </span>
            </h3>
            <div className={`flex h-32 flex-row items-center ${overflow}`}>
              {suppliers?.length ? (
                suppliers?.map((s, id) => {
                  const linkTo = getRedirectPath(s);
                  return (
                    <MenuItem
                      key={`${s.SupplierName}-${id}`}
                      onClick={handleClose}
                      id={s.SupplierID}
                      label={s.SupplierName}
                      logoURI={s.Logos?.find(sl => sl?.LogoTypeID?.toString() === LogoTypeEnum.Icon)?.URI}
                      isActive={
                        props.selectedModule?.includes('supplier') &&
                        props.selectedSupplier === s?.SupplierID?.toString()
                      }
                      locationUrl="string"
                      linkTo={linkTo}
                    />
                  );
                })
              ) : (
                <p
                  style={{
                    height: '100px',
                    lineHeight: '100px',
                    margin: '0 auto',
                  }}
                >
                  You have no Connected Suppliers yet.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface IMenuItem {
  onClick?: () => void;
  locationUrl: string;
  label?: string;
  id?: number;
  isActive?: boolean;
  linkTo?: string;
  logoURI?: string;
  showDaysLeft?: string;
  menuType?: string;
  icon?: string;
}

const MenuItem = (props: IMenuItem): JSX.Element => {
  const active = css({
    border: '1px solid #d3a755',
    backgroundColor: '#12475b',
    borderRadius: '4px',
  });

  const showDaysLeftInActive = css({
    border: '1px solid#E3A600',
    backgroundColor: '#12475b',
    marginBottom: '10px !important',
  });

  const gridStyle = css({
    display: 'grid',
    gridTemplateColumns: '70px',
    gridTemplateRows: `${props.showDaysLeft ? 'unset' : '60px'}`,
  });

  const {showDaysLeft} = props;
  let linkTo = props.linkTo || '/';

  return props.id ? (
    // if the MarketPlace Supplier has accepted your request, it will render with the Link
    <Link
      data-autoid={`lnk${props.label}`}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      key={props.label}
      to={linkTo}
    >
      <div
        className={`centerSvg mx-2 flex flex-col items-center  justify-center whitespace-pre-wrap px-1 ${
          props.isActive ? active : ''
        } ${gridStyle} ${showDaysLeft ? '' : 'h-20'}`}
        style={{minWidth: '60px'}}
      >
        {props.showDaysLeft && (
          <p className={`selling-days-left ${props.isActive ? '' : showDaysLeftInActive}`}>
            {props.showDaysLeft === 'EXPIRED' ? props.showDaysLeft : `${props.showDaysLeft} Days Left`}
          </p>
        )}
        {props.logoURI ? (
          <img {...css({width: '100%', height: '90%'})} src={props.logoURI} alt=""></img>
        ) : props.icon ? (
          <SVGIcon name={props.icon} />
        ) : (
          //display Supplier's initial if no business logo has uploaded.
          <p
            {...css({
              backgroundColor: '#b8b046',
              color: 'black',
              border: '1px solid #fff',
            })}
            className="relative m-auto h-8 w-8 justify-center rounded-full pt-1 text-center"
          >
            {NameInitials(props.label)}
          </p>
        )}
        <small className="w-full truncate leading-tight">{props.label}</small>
      </div>
    </Link>
  ) : (
    // if the MarketPlace Supplier has not accepted your request yet, it will render without Link and fading image.
    <div
      title="Pending Connection"
      className={`centerSvg mx-2 flex h-20 flex-col items-center justify-center whitespace-pre-wrap px-1 ${
        props.isActive ? active : ''
      } ${gridStyle}`}
      style={{minWidth: '60px'}}
    >
      {props.showDaysLeft && (
        <p className="selling-days-left">
          {props.showDaysLeft === 'EXPIRED' ? props.showDaysLeft : `${props.showDaysLeft} Days Left`}
        </p>
      )}
      {props.logoURI ? (
        <img {...css({width: '100%', height: '90%', opacity: 0.5})} src={props.logoURI} alt=""></img>
      ) : //display Supplier's initial if no business logo has uploaded.
      //check if this MenuItem is used by Modules, ie. in supplier context view isActive, it is collect svg.
      props.isActive ? (
        <SVGIcon name={SVGName.Collect} />
      ) : (
        <p
          {...css({
            backgroundColor: '#b8b046',
            opacity: 0.5,
            color: 'black',
            border: '1px solid #fff',
          })}
          className="relative m-auto h-8 w-8 justify-center rounded-full pt-1 text-center"
        >
          {NameInitials(props.label)}
        </p>
      )}
      <small className="mt-2 w-full truncate leading-tight">{props.label}</small>
    </div>
  );
};
