import React, {useCallback, useEffect, useMemo} from 'react';
import {FormikErrors, useFormikContext} from 'formik';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import {debounce} from 'lodash';

import {InputQ, TableHeader} from '../quote-management/CreateQuoteTable';
import {IQTColumn} from '../quote-management/CreateQuoteTable';
import {IServiceJob, IServiceJobLines, ServiceJobStatus} from '../../model/service-management/serviceJob';
import QuoteInventoryAutocomplete from '../quote-management/QuoteInventoryAutocomplete';
import LoadingIndicator from '../ui/LoadingIndicator';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import useServiceJobAPI from '../../services/useServiceJobAPI';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {useAppContext} from '../../context/app/appContext';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';
import {CalculationTable} from '../quote-management/CalculationTable';
import {LockIcon, UnLockIcon} from '../../assets/svg';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export function ServiceJobLabourAndMaterialTable() {
  //state
  const [showUnlockedWarning, setShowUnlockedWarning] = React.useState({
    show: false,
    index: 0,
    scope: 'lineItem',
  });

  const {values, setFieldValue, errors, setValues} = useFormikContext<IServiceJob>();
  // API
  const {updateServiceJob, setLineEditable, isLoading} = useServiceJobAPI();
  const {tenantInfo} = useAppContext();
  const {QuoteV289367} = useFeatureFlags().tenantOwned()

  const addLineInventory = () => {
    const newLineItem: IServiceJobLines[] = [
      {
        code: '',
        shortDescription: '',
        inventoryID: 0,
        quantity: 0,
        sellPriceEx: 0,
        lineTotalEx: 0,
        lineTotalInc: 0,
        uoM: '',
        isActive: true,
        sellPriceExString: '',
        lineTotalExString: '',
        sequenceNumber: values?.lines?.length,
        serviceJobID: values?.serviceJobID!,
        serviceQuoteLineID: null,
      },
    ];
    if (Array.isArray(values?.lines)) {
      const _lines = values?.lines.map(line => {
        if (!line.inventoryID) {
          line.code = '';
          line.shortDescription = '';
          line.quantity = 0;
          line.sellPriceEx = 0;
          line.lineTotalEx = 0;
        }
        return line;
      });

      setFieldValue('lines', [..._lines, {...newLineItem[0]}]);
    } else {
      setFieldValue('lines', newLineItem);
    }
  };

  useEffect(() => {
    if (values?.lines?.length === 0) {
      addLineInventory();
    }
  }, [values?.lines?.length]);

  const isJobCompleted = values?.status === ServiceJobStatus.Completed;

  const columns: IQTColumn[] = useMemo(
    () => [
      {
        title: 'Code',
        key: 'code',
        width: '15%',
        className: 'text-left',
        rowRender: (row: IServiceJobLines, rowIndex: number) => {
          const alreadyFilled = values?.lines.filter(a => a.isActive).map(item => item.inventoryID);
          return (
            <QuoteInventoryAutocomplete<IServiceJob>
              by="code"
              index={rowIndex}
              alreadyFilled={alreadyFilled}
              calculateLineTotal={calculateLineTotal}
              disabled={row.serviceQuoteLineID !== null || isJobCompleted}
            />
          );
        },
      },
      {
        title: 'Product',
        key: 'product',
        width: '35%',
        className: 'text-left',
        rowRender: (row: IServiceJobLines, rowIndex: number) => {
          const alreadyFilled = values?.lines.filter(a => a.isActive).map(item => item.inventoryID);
          return (
            <QuoteInventoryAutocomplete<IServiceJob>
              by="product"
              index={rowIndex}
              calculateLineTotal={calculateLineTotal}
              alreadyFilled={alreadyFilled}
              disabled={row.serviceQuoteLineID !== null || isJobCompleted}
            />
          );
        },
      },
      {
        title: 'Qty',
        key: 'qty',
        width: '8%',
        rowRender: (row: IServiceJobLines, rowIndex: number) => {
          return (
            <InputQ
              type="number"
              min={row.inventoryID ? 1 : 0}
              data-autoid={`txtQty-${rowIndex}`}
              placeholder="0"
              value={parseFloat(row.quantity?.toString() || '0')?.toString()}
              className="text-center text-black-800"
              showError={
                errors?.lines &&
                (errors?.lines as FormikErrors<IServiceJobLines>[])?.[rowIndex]?.quantity &&
                !Number(row.quantity || 0)
                  ? true
                  : false
              }
              containerProps={{
                className: `${QuoteV289367 ? 'border !border-[#707070] focus-within:border-primary' : ''} hover:border-primary hover:border`,
              }}
              onChange={e => handleQtyChange(e, row?.inventoryID)}
              onBlur={e => handleQtyChange(e, row?.inventoryID, true)}
              disableField={(row.serviceQuoteLineID !== null && !row.isEditable) || isJobCompleted}
            />
          );
        },
      },
      {
        title: 'UoM',
        key: 'uom',
        width: '11%',
        rowRender: (row: IServiceJobLines, rowIndex: number) => {
          return (
            <Typography
              data-autoid={`txtUoM-${rowIndex}`}
              className={`${(row.serviceQuoteLineID !== null && !row.isEditable) || isJobCompleted ? 'text-black-300' : null} p-2.5 text-center`}
              variant="small"
            >
              {row.uoM}
            </Typography>
          );
        },
      },
      {
        title: 'Price',
        key: 'price',
        width: '15%',
        className: 'text-right',
        rowRender: (row: IServiceJobLines, rowIndex: number) => (
          <Typography
            data-autoid={`txtPrice-${rowIndex}`}
            className={`${(row.serviceQuoteLineID !== null && !row.isEditable) || isJobCompleted ? 'text-black-300' : 'text-black-800'} p-2.5 text-right`}
            variant="small"
          >
            {Number(row?.sellPriceEx).toFixed(2)}
          </Typography>
        ),
      },
      {
        title: 'Line Total',
        key: 'lineTotal',
        width: '15%',
        className: 'text-right',
        rowRender: (row: IServiceJobLines, rowIndex: number) => {
          return (
            <div
              className={`${
                errors?.lines &&
                (errors?.lines as FormikErrors<IServiceJobLines>[])?.[rowIndex]?.lineTotalEx &&
                !Number(row.lineTotalEx || 0)
                  ? 'hover:border-transparent'
                  : 'hover:border-primary'
              } flex h-full items-center justify-between border border-transparent`}
            >
              <InputQ
                type="number"
                value={row.lineTotalEx || ''}
                placeholder="0.00"
                data-autoid={`txtLineTotal-${rowIndex}`}
                className="text-right"
                onChange={e => {
                  if (e.target.value.match(/\./g)) {
                    const [, decimal] = e.target.value.split('.');

                    // restrict value to only 2 decimal places
                    if (decimal?.length > 2) {
                      // do nothing
                      return;
                    }
                  }
                  handleLineTotalChange(e, row?.inventoryID);
                }}
                showError={
                  errors?.lines &&
                  (errors?.lines as FormikErrors<IServiceJobLines>[])?.[rowIndex]?.lineTotalEx &&
                  !Number(row.lineTotalEx || 0)
                    ? true
                    : false
                }
                disableField={(row.serviceQuoteLineID !== null && !row.isEditable) || isJobCompleted}
              />
            </div>
          );
        },
      },
      {
        title: '',
        key: 'action',
        width: '1%',
        className: 'text-center',
        rowRender: (row: IServiceJobLines, index: number) =>
          (index === 0 && !row.code) || (!row.isEditable && row.serviceQuoteLineID !== null) ? (
            <div className="w-10" />
          ) : (
            <IconButton
              variant="text"
              className="cursor-pointer active:bg-transparent"
              onClick={() => {
                onRemoveLine(row?.inventoryID, index);
              }}
              name={`Remove-${index}`}
              disabled={isLoading || isJobCompleted}
              ripple={false}
            >
              {<WidgetDeleteLogo />}
            </IconButton>
          ),
      },
      {
        title: '',
        key: 'lockAndUnlock',
        width: '1%',
        className: 'text-center',
        rowRender: (row: IServiceJobLines, index: number) =>
          !row.isEditable && row.serviceQuoteLineID !== null ? (
            <IconButton
              onClick={() =>
                setShowUnlockedWarning({
                  show: true,
                  index,
                  scope: 'lineItem',
                })
              }
              variant="text"
              className="active:bg-transparent"
              name={`disable-${index}`}
              disabled={isLoading || isJobCompleted}
              ripple={false}
            >
              <LockIcon />
            </IconButton>
          ) : row.isEditable && row.serviceQuoteLineID !== null ? (
            <div className="flex w-10 justify-center">
              <UnLockIcon />
            </div>
          ) : (
            <div className="w-10" />
          ),
      },
    ],
    [values?.lines],
  );

  const calculateLineTotal = async (
    _lines: IServiceJobLines[],
    _values: IServiceJob,
    shipping: number | string,
    rowIndex: number,
    discount?: string,
    discountMode?: DISCOUNT_MODE,
    isRemoveLine?: boolean,
    unlock?: boolean,
  ) => {
    if (!Array.isArray(_lines)) {
      return;
    }
    let _discount = 0;
    if (Number(discount) && discountMode === DISCOUNT_MODE.DOLLAR) {
      _discount = Number(discount);
      discountMode = DISCOUNT_MODE.DOLLAR;
    } else if (Number(discount) && discountMode === DISCOUNT_MODE.PERCENT) {
      _discount = Number(discount) / 100;
      discountMode = DISCOUNT_MODE.PERCENT;
    } else {
      discountMode = DISCOUNT_MODE.NONE;
    }

    const payload: Partial<IServiceJob> = {
      serviceJobID: _values?.serviceJobID!,
      discount: _discount,
      discountMode: discountMode,
      shipping: Number(shipping),
      isActive: true,
    };

    if (rowIndex !== -1 && !isRemoveLine && _lines[rowIndex]?.serviceJobLineGUID) {
      payload.lines = [_lines[rowIndex]];
      if (
        unlock &&
        _lines[rowIndex]?.inventoryID === tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId
      ) {
        payload.isDiscountEditable = true;
      } else if (
        unlock &&
        _lines[rowIndex]?.inventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      ) {
        payload.isShippingEditable = true;
      }
    } else {
      payload.lines = _lines;
    }

    try {
      const updatedQuote = await updateServiceJob(payload);
      // update tax/total in formik
      setFieldValue('totalTax', updatedQuote.totalTax);
      setFieldValue('totalInc', updatedQuote.totalInc);

      if (unlock) {
        setFieldValue('isDiscountEditable', updatedQuote.isDiscountEditable);
        setFieldValue('isShippingEditable', updatedQuote.isShippingEditable);
      }

      // updateVariantTotalAmount?.(updatedQuote.totalInc || 0);
      if (isRemoveLine || !_lines?.[rowIndex]?.serviceJobLineGUID || discountMode !== DISCOUNT_MODE.NONE) {
        setFieldValue('lines', updatedQuote.lines);
      }
    } catch (e) {
      console.error('update quote', e);
    }
  };

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>, inventoryID: number, onBlur?: boolean) => {
    if (onBlur && e.target.value) return;
    if (Array.isArray(values?.lines)) {
      const index = values?.lines?.findIndex(li => String(li.inventoryID) === String(inventoryID));
      const Lines = [...values?.lines];
      const quantity =
        !e.target.value && onBlur
          ? 0
          : e.target.value && parseFloat(e.target.value) <= 0
            ? 0
            : parseFloat(e.target.value) || 0;
      Lines[index].quantity = quantity;
      const lineTotalEx = quantity * (Number(Lines?.[index]?.sellPriceEx?.toFixed(2)) || 0);
      setFieldValue(`lines[${index}].quantity`, quantity);
      setFieldValue(`lines[${index}].lineTotalEx`, lineTotalEx);
      if (Lines[index].inventoryID)
        debounceCalculateLineTotal(
          Lines,
          values,
          values?.shipping,
          index,
          values?.discountString,
          values?.discountMode,
        );
    }
  };

  const handleDiscountChange = (value: string, type: DISCOUNT_MODE) => {
    if (type === DISCOUNT_MODE.DOLLAR) {
      setValues({
        ...values,
        discountString: value,
        discount: Number(value),
        discountMode: DISCOUNT_MODE.DOLLAR,
      });
      debounceCalculateLineTotal(values?.lines, values, values?.shipping, -1, value, DISCOUNT_MODE.DOLLAR);
    } else if (type === DISCOUNT_MODE.PERCENT) {
      setValues({
        ...values,
        discountString: value,
        discount: Number(value) / 100,
        discountMode: DISCOUNT_MODE.PERCENT,
      });
      debounceCalculateLineTotal(values?.lines, values, values?.shipping, -1, value, DISCOUNT_MODE.PERCENT);
    }
  };

  const debounceCalculateLineTotal = useCallback(
    debounce(
      (
        lines: IServiceJobLines[],
        _values: IServiceJob,
        shipping: number | string,
        rowIndex: number,
        discount?: string,
        discountMode?: DISCOUNT_MODE,
        isRemoveLine?: boolean,
      ) => {
        calculateLineTotal(lines, _values, shipping, rowIndex, discount, discountMode, isRemoveLine);
      },
      1000,
    ),
    [],
  );

  const onRemoveLine = (inventoryID: number, rowIndex: number) => {
    if (inventoryID === 0) {
      const _lines = values?.lines.filter(line => line.inventoryID !== 0);
      setFieldValue('lines', _lines);
    } else {
      const _lines = values?.lines.map((line, i) => {
        if (i === rowIndex) {
          line.isActive = false;
        }
        return line;
      });
      setFieldValue('lines', _lines);
      calculateLineTotal(
        _lines,
        values,
        values?.shipping,
        rowIndex,
        values?.discountString,
        values?.discountMode,
        true,
      );
    }
  };

  const handleLineTotalChange = (e: React.ChangeEvent<HTMLInputElement>, inventoryID: number) => {
    if (Array.isArray(values?.lines)) {
      const index = values?.lines.findIndex(li => String(li.inventoryID) === String(inventoryID));
      const Lines = [...values?.lines];
      const lineTotal = parseFloat(e.target.value) < 0 ? 0 : parseFloat(e.target.value);
      Lines[index].lineTotalEx = parseFloat(lineTotal.toFixed(2));
      const sellPriceEx = lineTotal / (Lines[index].quantity || 1) || 0;
      Lines[index].sellPriceEx = sellPriceEx;
      setFieldValue(`lines[${index}].lineTotalEx`, lineTotal);
      setFieldValue(`lines[${index}].sellPriceEx`, sellPriceEx);
      if (Lines[index].inventoryID)
        debounceCalculateLineTotal(
          Lines,
          values,
          values?.shipping,
          index,
          values?.discountString,
          values?.discountMode,
        );
    }
  };

  const handleEditLine = async (rowIndex: number) => {
    const updatedQuote = await setLineEditable(values?.serviceJobID!, values?.lines[rowIndex].serviceJobLineID!);

    if (updatedQuote) {
      setFieldValue('lines', updatedQuote.lines);
    }
  };

  return (
    <>
      <LoadingIndicator
        isLoading={isLoading}
        position={{
          height: '100% !important',
          display: 'flex',
          position: 'absolute',
          left: '0',
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        size="md"
      />
      <div className="relative flex h-[calc(100vh-376px)] w-full flex-col justify-between overflow-auto text-black">
        <div className="h-[calc(100vh-80px)]">
          <div>
            <table className="w-full pb-1">
              <TableHeader heading={columns} />
              <tbody>
                {values?.lines.map((line, index) => (
                  <tr key={index + '' + line.inventoryID} className="relative w-full">
                    {columns.map((table: IQTColumn) => {
                      if (
                        !line.isActive ||
                        line.inventoryID === tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId ||
                        line.inventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
                      )
                        return null;
                      return (
                        <td
                          className={`m-auto h-10 border-b border-[#EAEAEA] p-0 odd:bg-[#FAFAFA] even:bg-[#FFFFFF] ${table.className}`}
                          width={table.width}
                          key={table.key}
                        >
                          {table.rowRender(line, index)}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="px-5 py-2.5">
            <Button
              variant="text"
              className="focus:bg-transparent active:bg-transparent"
              ripple={false}
              onClick={addLineInventory}
              disabled={isJobCompleted}
            >
              Add New Line
            </Button>
          </div>
        </div>
        <CalculationTable
          values={values}
          handleDiscountChange={handleDiscountChange}
          handleUnlock={index => setShowUnlockedWarning({show: true, index, scope: 'CalculationTable'})}
          handleShippingChange={value =>
            debounceCalculateLineTotal(
              values?.lines,
              values,
              Number(value),
              -1,
              values?.discountString,
              values?.discountMode,
            )
          }
          setFieldValue={setFieldValue}
          serviceJob={true}
          isServiceJobFromQuote={values?.serviceQuoteID !== null}
          isDiscountEditable={values?.isDiscountEditable}
          isShippingEditable={values?.isShippingEditable}
          disabled={isJobCompleted}
        />
      </div>
      {showUnlockedWarning.show && (
        <AlertDialog
          size="sm"
          title="Unlocking line item"
          headingTextSize="h2"
          contentClass="text-sm w-[260px] mx-auto"
          content="Unlocking this line item enables editing and deleting of the line item."
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () =>
                setShowUnlockedWarning({
                  show: false,
                  index: 0,
                  scope: 'lineItem',
                }),
            },
            {
              label: 'Unlock',
              action: () => {
                if (showUnlockedWarning.scope === 'lineItem') {
                  handleEditLine(showUnlockedWarning.index);
                } else {
                  calculateLineTotal(
                    values?.lines,
                    values,
                    values?.shipping,
                    showUnlockedWarning.index,
                    values?.discountString,
                    values?.discountMode,
                    false,
                    true,
                  );
                }
                setShowUnlockedWarning({
                  show: false,
                  index: 0,
                  scope: 'lineItem',
                });
              },
            },
          ]}
        />
      )}
    </>
  );
}
