import React, {useState} from 'react';
import {Box, Link, makeStyles} from '@material-ui/core';
import {css} from 'glamor';
import {TransactionAttachments} from './TransactionAttachments';
import Skeleton from 'react-loading-skeleton';
import {IAddress} from '../../../model/address/Address';
import {PaymentStatusType} from '../../../model/constants/Constants';
import {IInvoice} from '../../../model/invoice/Invoice';
import {ITxDeduction} from '../../../model/payment/PaymentT';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {LogoTypeEnum} from '../../../model/Tenant';
import {PriceFormat, NameInitials} from '../../../utils/formatter';
import {SButton} from '../../buttons/Button';
import {SPopover} from '../../modals/modalSpendaMeterialUI';
import {PaymentSummary} from '../PaymentSummary';
import {TransactionLines} from './TransactionLines';
import STooltip from '../../data-display/Tooltip';
import {ResolvedConvoFetchAndDownload} from '../../conversationChatBox/ResolvedConvoDownload';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from 'spenda-ui-react';

const tabs = [
  {label: 'Detailed view', value: 0},
  {label: 'Customer view', value: 1},
];

export const InvoiceDetailsView = (props: {
  txnLine?: any;
  breakPoint?: any;
  tx?: IInvoice;
  supplier?: ISupplierWhoRaisedInvoice;
  isLoading?: boolean;
  bgColor?: 'white';
  isPaid?: boolean;
  showAmountFieldOnPaymentSummaryPopover?: boolean;
  showSalesOrderRefNumber?: boolean;
  isCreditNote?: boolean;
  isAPInv?: boolean;
  isPaymentInProgress?: boolean;
  isPaymentScheduled?: boolean;
  showSummaryTabs?: boolean;
  hideQuantity?: boolean;
  QuoteV2?: boolean;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    tx,
    supplier,
    isCreditNote,
    isAPInv,
    isPaymentInProgress,
    isPaymentScheduled,
    isPaid,
    showSummaryTabs,
    hideQuantity,
    QuoteV2,
  } = props;
  const headerTopStyle = css({
    //boxShadow: "0px -1.5px 1px 0px #c2c2c27a",
    borderRadius: isAPInv ? '0.25rem 0 0.25rem 0.25rem' : '1rem 0 1rem 1rem',
    '&:after': {
      content: ' ',
      position: 'absolute',
      top: '0',
      right: '0',
      borderWidth: '38px 0px 0px 40px',
      borderStyle: 'solid',
      borderColor: `${props.bgColor || (isAPInv ? '#a7abaee6' : '#f4f8fb')} #fff #ffffff00 #d8d8d800`,
      backgroundColor: '#E1E1E1',
      display: 'block',
      width: 0,
      zIndex: props.bgColor === 'white' ? 0 : 0, //re-using the color prop to put the layer behind in white and put the layer as it is in blue background
      WebkitBorderBottomLeftRadius: '17px',
      overflowY: 'auto',
      borderRadius: '0 0 0 17px',
    },
  });

  const customScrollBarStyle = css({
    '::-webkit-scrollbar': {
      width: '22px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      marginTop: '0.75rem',
      marginBottom: '1.25rem',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#CCE6F1',
      borderRadius: '20px',
      border: '6px solid transparent',
      backgroundClip: 'content-box',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#1C78AD',
    },
  });

  const LogoURI = supplier?.Logos.find(x => x.LogoTypeID.toString() === LogoTypeEnum.Logo)?.URI;
  const [editAddressType, setEditAddressType] = useState<'Billing' | 'Shipping' | undefined>(undefined);
  const [addressToView, setAddressToView] = useState<IAddress | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const aRContactEmailAddress =
    tx && tx.ARContactDetails
      ? tx.ARContactDetails
      : supplier
        ? supplier.ARContactEmailAddress || supplier.PrimaryContactEmail || ''
        : '';

  const onClickAddressToView = (
    addressType: 'Billing' | 'Shipping',
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event?.currentTarget);
    setEditAddressType(addressType);
    setAddressToView(addressType === 'Billing' ? tx && tx.BillingAddress : tx && tx.ShippingAddress);
  };

  const open = Boolean(anchorEl);

  const speechBubbleModal = css({
    position: 'fixed',
    padding: '5px',
    background: '#FFF',
    borderRadius: '10px',
    border: '1px solid #0082BA',
    fontSize: props.breakPoint && '14px',
    maxWidth: props.breakPoint ? 'calc(100vw - 11rem)' : 'calc(100vh - 34rem)',
    textAlign: 'center',
  });

  const PopoverStyle = {
    root: {
      '& .MuiPaper-root': {
        overflow: 'hidden',
        backgroundColor: 'transparent !important',
        top: props.breakPoint ? 'calc(100vh - 60%) !important' : 'calc(100vh - 58%) !important',
        left: props.breakPoint ? 'calc(100vw - 67%)!important' : 'calc(100vw - 59%) !important',
      },
    },
  };

  const borderStyle = css({
    borderColor: '#ccc !important',
  });

  const paidBorderStyle = css({
    borderColor: '#317e60',
    backgroundColor: '#3C9F78',
    color: '#6ae1b2',
  });

  const inProgressBorderStyle = css({
    borderColor: 'rgba(198, 138, 25, .2)',
    color: '#C68A19',
  });

  const useStyles = makeStyles(PopoverStyle);

  const APInvoiceDetailsStyles = makeStyles({
    mainBoxStyles: {
      padding: '30px',
      fontFamily: 'Poppins',
      height: '806px',
      color: '#000',
    },
    bigTextOuter: {
      alignItems: 'end',
      justifyContent: 'end',
      paddingBottom: '2px',
      marginRight: '0.25rem',
    },
    bigText: {
      fontSize: '1.375rem',
      fontWeight: 700,
      color: '#000',
    },
    paidBox: {
      width: '161px',
      height: '56px',
      padding: '5px',
      border: 'solid 3px #E1E1E1',
      borderRadius: '13px',
    },
    amountType: {
      fontSize: '10px',
    },
    logoHeader: {
      alignItems: 'end',
    },
    supBigName: {
      fontSize: '22px',
      fontWeight: 300,
      color: '#000',
    },
  });

  const apClasses = APInvoiceDetailsStyles();

  const classes = useStyles();

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const failedPayments: ITxDeduction[] =
    (tx?.Payments &&
      tx?.Payments.filter(p => p.Status?.toLocaleLowerCase() === PaymentStatusType.Failed.toLocaleLowerCase())) ||
    [];

  const getBorderClass = () => {
    switch (true) {
      case isPaid:
        return paidBorderStyle;

      case isPaymentInProgress:
        return inProgressBorderStyle;

      default:
        return borderStyle;
    }
  };

  const renderInvoiceDetailView = (showSummaryLines: boolean = false) => {
    return (
      <>
        <div className={`h-full pt-2 ${props.breakPoint ? 'pt-2' : ''} ${customScrollBarStyle}`}>
          <div className={`flex items-center justify-between ${isAPInv ? apClasses.logoHeader : ''}`}>
            <div>
              {LogoURI ? (
                <img src={LogoURI} style={{maxWidth: '100px', maxHeight: '100px'}} />
              ) : supplier?.Name ? (
                <p
                  {...css({
                    backgroundColor: '#b8b046',
                    color: 'black',
                    border: '1px solid #fff',
                    width: '100px',
                    height: '100px',
                  })}
                  className="relative m-auto flex h-8 w-8 items-center justify-center rounded-full pt-1 text-center text-5xl"
                >
                  {NameInitials(supplier?.Name)?.toUpperCase()}
                </p>
              ) : (
                <Skeleton circle={true} height={'6rem'} width={'6rem'} />
              )}
            </div>
            <div className={isAPInv ? apClasses.bigTextOuter : `mr-5`}>
              <h1 className={isAPInv ? apClasses.bigText : `flex items-end text-4xl font-bold tracking-wider`}>
                {isCreditNote ? 'Credit Note' : 'Tax Invoice'}
              </h1>
              {failedPayments.length ? (
                <PaymentSummary
                  payments={failedPayments}
                  showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
                ></PaymentSummary>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="flex justify-between">
            <div
              data-autoid="txtSupplierName"
              className={`mb-1 flex items-end text-xl ${isAPInv ? apClasses.supBigName : ''}`}
            >
              {supplier ? supplier?.Name : <Skeleton width={`${props.breakPoint ? '8rem' : '10rem'}`} />}
            </div>

            <div className="flex justify-between">
              <div
                className={`flex flex-col border-4 ${getBorderClass()} ${
                  props.breakPoint ? 'w-1/2' : 'w-1/5'
                } flex-auto items-center rounded-lg p-1 ${isAPInv ? apClasses.paidBox : ''}`}
              >
                {isPaid || isPaymentInProgress || isPaymentScheduled ? (
                  <h1 data-autoid="lblStatus" className="px-2 text-4xl font-bold uppercase">
                    {isPaymentScheduled ? 'SCHEDULED' : isPaymentInProgress ? 'IN PROGRESS' : 'PAID'}
                  </h1>
                ) : (
                  <>
                    <div className={isAPInv ? apClasses.amountType : ''}>
                      {isCreditNote ? 'Credit Available:' : 'Amount Due:'}
                    </div>
                    <div className={isAPInv ? 'text-lg font-medium' : ''}>
                      {tx ? PriceFormat(tx?.Balance) : <Skeleton width={`${props.breakPoint ? '5rem' : '7rem'}`} />}
                    </div>
                  </>
                )}
              </div>
              <div className="mr-3 mt-3">
                <ResolvedConvoFetchAndDownload txDatType={tx?.DatTypeID} txGuid={tx?.GUIDstring} />
              </div>
              {(tx?.AttachmentCount || 0) > 0 && (
                <TransactionAttachments Counter={tx?.AttachmentCount} Attachments={tx?.Attachments} />
              )}
            </div>
          </div>

          <div className="mb-1 flex justify-between">
            <div className={isAPInv ? 'text-xs' : ''}>
              <div className="mb-2 flex items-center md:mb-1">
                <div>
                  <label className={isAPInv ? 'text-xs' : 'text-base'}>ABN:</label>
                </div>
                <div className="flex">
                  <p className={isAPInv ? 'text-xs' : 'text-base'}>
                    {supplier ? supplier?.ABN : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                  </p>
                </div>
              </div>

              <div className="mb-2 flex items-center md:mb-1">
                <div>
                  <label className={isAPInv ? 'text-xs' : 'text-base'}>phone:</label>
                </div>
                <div className="flex">
                  <p className={isAPInv ? 'text-xs' : 'text-base'}>
                    {supplier ? supplier?.BusinessPhone : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                  </p>
                </div>
              </div>

              <div className="mb-2 flex items-center md:mb-1">
                <div>
                  <label className={isAPInv ? 'text-xs' : 'text-base'}>email:</label>
                </div>
                <div className="flex">
                  <p className={isAPInv ? 'text-xs' : 'text-base'}>{aRContactEmailAddress}</p>
                </div>
              </div>

              <div className="mb-2 flex items-center md:mb-1">
                <div>
                  <label className={isAPInv ? 'text-xs' : 'text-base'}>web:</label>
                </div>
                <div className="flex">
                  <p className={isAPInv ? 'text-xs' : 'text-base'}>
                    {supplier ? supplier?.Website : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-0 mt-4 flex items-center justify-end">
                <div>
                  <label
                    className={`text-gray-800  ${props.breakPoint ? '' : 'tracking-wide'} ${
                      isAPInv ? 'text-base font-medium' : 'text-xl'
                    } `}
                  >
                    {isCreditNote ? 'Credit Note Ref #:' : 'Invoice #:'}
                  </label>
                </div>
                <div className="flex">
                  {/* Mobile devices enhancement: Adding tooltip upon clicking on mobile */}
                  {props.breakPoint ? (
                    <STooltip
                      open={showTooltip}
                      onOpen={() => setShowTooltip(true)}
                      onClose={() => setShowTooltip(false)}
                      title={<>{tx?.RefNumber}</>}
                      placement="bottom"
                    >
                      <div
                        onClick={() => setShowTooltip(!showTooltip)}
                        className={`text-xl ${props.breakPoint ? 'w-24 truncate' : ''}`}
                      >
                        <Link color="secondary">
                          {tx ? tx.RefNumber : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                        </Link>
                      </div>
                    </STooltip>
                  ) : (
                    <p
                      title={tx?.RefNumber}
                      className={`${props.breakPoint ? 'w-24 truncate' : ''}  ${
                        isAPInv ? 'text-base font-medium' : 'text-xl font-bold'
                      }`}
                    >
                      {tx ? tx.RefNumber : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                    </p>
                  )}
                </div>
              </div>
              {tx?.VendorName && (
                <div className="-mt-2 flex items-center justify-end">
                  <div>
                    <label className=" text-xl tracking-wide text-gray-800">Vendor: </label>
                  </div>
                  <div className="flex">
                    <p className="text-xl font-bold">{tx.VendorName}</p>
                  </div>
                </div>
              )}
              {tx?.VendorTransRefNumber && (
                <div className="-mt-2 flex items-center justify-end">
                  <div>
                    <label className=" text-xl tracking-wide text-gray-800">
                      Vendor {props.isCreditNote ? `Credit Note` : `Invoice`} #:{' '}
                    </label>
                  </div>
                  <div className="flex">
                    <p className="text-xl font-bold">{tx.VendorTransRefNumber}</p>
                  </div>
                </div>
              )}

              {props.showSalesOrderRefNumber && !isCreditNote && (
                <div className="mb-2 flex items-start justify-end md:mb-1">
                  <div>
                    <label className="text-base">{tx?.RefTransRefNumber && 'Sales Order #:'}</label>
                  </div>
                  <div className="flex">
                    <p className="text-base">
                      {tx ? tx?.RefTransRefNumber : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                    </p>
                  </div>
                </div>
              )}

              <div className={`mb-2 flex items-start justify-end md:mb-1 ${isAPInv ? 'text-xs font-normal' : ''}`}>
                <div>
                  <label className={isAPInv ? 'text-xs' : 'text-base'}>
                    {isCreditNote ? 'Issue Date:' : 'Invoice Date:'}
                  </label>
                </div>
                <div className="flex">
                  <p className={isAPInv ? 'text-xs' : 'text-base'}>
                    {tx ? tx?.TransDateString : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                  </p>
                </div>
              </div>

              {!isCreditNote && (
                <div className="mb-2 flex items-start justify-end md:mb-1">
                  <div>
                    <label className={isAPInv ? 'text-xs' : 'text-base'}>Due Date:</label>
                  </div>
                  <div className="flex items-start">
                    <p className={isAPInv ? 'text-xs' : 'text-base'}>
                      {tx ? tx?.DueDateString : <Skeleton width={`${props.breakPoint ? '5rem' : '10rem'}`} />}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className={`flex flex-wrap justify-between py-4 ${isAPInv ? 'text-xs font-normal' : ''}`}>
            <div style={{flex: 2}}>
              <div className="">
                <div>
                  <label className={`tracking-wide ${isAPInv ? 'text-base font-medium' : 'text-xl font-normal'}`}>
                    Bill To:
                  </label>
                </div>
                <div>
                  <p className="text-xl font-normal tracking-wide">
                    {tx ? tx?.AccountCustomerName || tx?.CustomerName : <Skeleton width={`10rem`} />}
                  </p>
                </div>
              </div>
              {tx ? (
                <p>
                  {tx?.ContactFirstName} {tx?.ContactLastName}
                </p>
              ) : (
                <Skeleton width={'8rem'} />
              )}
              <p>{tx ? tx?.ContactEmailAddress : <Skeleton width={`7rem`} />}</p>
              {props.showSalesOrderRefNumber && (
                <p>
                  {tx?.CustomerRefTransRefNumber && 'Ref:'}
                  {tx ? tx?.CustomerRefTransRefNumber : <Skeleton width={`6rem`} />}
                </p>
              )}
            </div>
            {/* Rendering for Mobile & desktop according to breakpoint */}
            {props.breakPoint ? (
              <div className="grid">
                <div className="m-4 inline-grid" {...css({backgroundColor: '#d6ebf3', fontSize: '0.95rem'})}>
                  <SButton
                    lineHeight="xs"
                    onClick={e => onClickAddressToView('Billing', e)}
                    variant="outlined"
                    textColor="blue"
                    border="1px solid #2F97BC"
                  >
                    Billing Address
                  </SButton>
                </div>
                <div className="m-4 inline-grid" {...css({backgroundColor: '#d6ebf3', fontSize: '0.95rem'})}>
                  <SButton
                    lineHeight="xs"
                    onClick={e => onClickAddressToView('Shipping', e)}
                    variant="outlined"
                    textColor="blue"
                    border="1px solid #2F97BC"
                  >
                    Shipping Address
                  </SButton>
                </div>
              </div>
            ) : (
              <div className="flex justify-around" style={{flex: 3}}>
                <div className="mr-40">
                  <div className="flex">
                    <div>
                      <label
                        className={`mb-1  tracking-wide ${isAPInv ? 'text-base font-medium' : 'text-xl font-normal'}`}
                      >
                        Shipping:
                      </label>
                    </div>
                  </div>
                  {tx ? (
                    <>
                      <p>{tx?.ShippingAddress?.StreetAddress}</p>
                      <p>{tx?.ShippingAddress?.City && ' ' + tx?.ShippingAddress?.City}</p>
                    </>
                  ) : (
                    <Skeleton width={`10rem`} />
                  )}
                  {tx ? (
                    <p>
                      {tx?.ShippingAddress?.State} {tx?.ShippingAddress?.Country} {tx?.ShippingAddress?.PostCode}
                    </p>
                  ) : (
                    <Skeleton width={`8rem`} />
                  )}
                </div>
                <div>
                  <div className="flex">
                    <div>
                      <label
                        className={`mb-1  tracking-wide ${isAPInv ? 'text-base font-medium' : 'text-xl font-normal'}`}
                      >
                        Billing:
                      </label>
                    </div>
                  </div>
                  {tx ? (
                    <>
                      <p>{tx?.BillingAddress?.StreetAddress}</p>
                      <p>{tx?.BillingAddress?.City && ' ' + tx?.BillingAddress?.City}</p>
                    </>
                  ) : (
                    <Skeleton width={`10rem`} />
                  )}
                  {tx ? (
                    <p>
                      {tx?.BillingAddress?.State} {tx?.BillingAddress?.Country} {tx?.BillingAddress?.PostCode}
                    </p>
                  ) : (
                    <Skeleton width={`8rem`} />
                  )}
                </div>
              </div>
            )}
          </div>
          <TransactionLines
            showSummaryLines={showSummaryLines}
            hideQuantity={hideQuantity || true}
            txnLine={showSummaryLines ? tx?.SummaryLines : tx?.Lines}
            breakPoint={props.breakPoint}
            tx={tx}
            isPaid={props.isPaid}
            showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
            isAPInv={isAPInv ?? false}
          />
          <div>
            <div className={`${isAPInv ? 'text-base' : 'text-xl'} mb-1 flex items-end`}> Notes:</div>
            <div className="h-32">{tx ? tx?.DeliveryNote : <Skeleton width={`12rem`} />}</div>
          </div>
        </div>
        {addressToView && (
          <SPopover
            id="click-over-popover"
            open={open}
            classes={{root: classes.root}}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box className={`p-2 ${speechBubbleModal}`}>
              {addressToView.StreetAddress ? (
                <>
                  <p>
                    {addressToView?.StreetAddress} {addressToView?.City}
                  </p>
                  <p>
                    {addressToView?.State} {addressToView?.Country} {addressToView?.PostCode}
                  </p>
                </>
              ) : (
                <p>
                  We have not recorded a <br />
                  {editAddressType} address for this invoice.
                </p>
              )}
            </Box>
          </SPopover>
        )}
      </>
    );
  };

  return (
    <header
      className={`${isAPInv && apClasses.mainBoxStyles} relative h-full bg-white px-3 sm:px-3 ${
        props.breakPoint ? '' : headerTopStyle
      }`}
    >
      {showSummaryTabs ? (
        <Tabs value={selectedTab} className="flex w-full flex-col items-center">
          <TabsHeader
            className="h-[40px] w-[335px] bg-[#ECECEC]"
            indicatorProps={{
              className: 'bg-spenda-sBlue shadow-none !text-white',
            }}
          >
            {tabs?.map(({label, value}) => (
              <Tab
                key={value}
                value={value}
                className={`${selectedTab === value ? 'font-medium text-white' : 'font-medium'}`}
                onClick={() => setSelectedTab(value)}
                data-autoid={`tab-${label}`.replace(/ /g, '')}
                title={label}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody className="!overflow-hidden overflow-y-auto">
            {tabs?.map(({value}) => (
              <TabPanel key={value} value={value} className="w-full !p-0">
                {renderInvoiceDetailView(value === 1)}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      ) : (
        renderInvoiceDetailView(tx?.SummaryLines?.length && QuoteV2 ? true : false)
      )}
    </header>
  );
};
