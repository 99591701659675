import React, {useMemo} from 'react';
import useTheme from '../../context/theme-context/useTheme';

interface VisibleContentProps {
  keyPath: string;
  children: React.ReactNode;
}

const getNestedValue = (obj: any, keyPath: string): any => {
  try {
    return keyPath.split('.').reduce((acc, key) => {
      if (acc && key in acc) {
        return acc[key];
      }
      return undefined;
    }, obj);
  } catch (e) {
    return undefined;
  }
};

// The VisibleContent component uses the getNestedValue function to determine whether to render the children.
// If the value at the keyPath is true, it returns null (renders nothing).
// Otherwise, it renders the children.

const VisibleContent: React.FC<VisibleContentProps> = ({keyPath, children}) => {
  const {content} = useTheme();
  const value = useMemo(() => getNestedValue(content, keyPath), [content, keyPath]);

  if (value === true) {
    return null;
  }

  return <>{children}</>;
};

export default VisibleContent;
