import { useContext } from 'react';
import ThemeContext, { IThemeContextType } from './ThemeContext';

const useTheme = (): IThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default useTheme;