import React from 'react';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {css} from 'glamor';
import {IInvoice} from '../../../model/invoice/Invoice';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {InvoiceDetailsView} from './InvoiceDetailsView';
import {BatchPaymentHistoryView} from './batchInvoiceDetailsView';
import {InvoiceDetailsFooter} from './InvoiceDetailsFooter';
import {IInvoicePaymentAllocation} from '../../../model/invoice/InvoicePaymentAllocation';
import {PaymentView} from './PaymentView';

interface IInvoiceDetailProps {
  tx?: IInvoice | IInvoicePaymentAllocation;
  isPaid?: boolean;
  supplier?: ISupplierWhoRaisedInvoice;
  bgColor?: 'white';
  className?: string;
  isStickyFooter?: boolean; // whether the footer is sticky or Fixed
  fullWidth?: boolean; // whether we want component full width or not. FYI - by default max width class is max-w-4xl
  showBoxShadow?: boolean;
  showAmountFieldOnPaymentSummaryPopover?: boolean;
  showSalesOrderRefNumber?: boolean;
  footer?: React.ReactNode;
  isCreditNote?: boolean;
  isPayment?: boolean;
  // isExpanded?: boolean;
  isPaymentInProgress?: boolean;
  isPaymentScheduled?: boolean;
  // onAmountChanged?: (updatedAmount?: number) => void;
  // onError?: (err: boolean) => void;
  // onClose?: () => void;
  isAPInvoice?: boolean;
  isInvoicePaymentAllocation?: boolean;
  showSummaryTabs?: boolean;
  hideQuantity?: boolean;
  QuoteV2?: boolean;
}

export const InvoiceDetail = ({
  tx,
  supplier,
  bgColor,
  className,
  isStickyFooter,
  fullWidth,
  showBoxShadow = true,
  showAmountFieldOnPaymentSummaryPopover = true,
  showSalesOrderRefNumber = true,
  footer,
  isPaid,
  isCreditNote = false,
  isPayment = false,
  isPaymentInProgress = false,
  isAPInvoice,
  isInvoicePaymentAllocation,
  isPaymentScheduled,
  showSummaryTabs,
  hideQuantity,
  QuoteV2,
}: IInvoiceDetailProps) => {
  const customBoxShadowStyle = css({
    boxShadow: showBoxShadow ? '0px 16px 4px 0px #c2c2c2f5' : 'none',
    borderTopRightRadius: '2.5rem',
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(0, 415));

  const bgClass = bgColor === 'white' ? 'bg-white' : '';

  return (
    <div className={`mx-auto h-full ${isAPInvoice || QuoteV2 ? 'w-full max-w-[650px]' : ''} ${bgClass} ${className} `}>
      <div
        className={`mx-auto h-full rounded-tl-lg ${bgClass} ${matches ? '' : customBoxShadowStyle} ${
          fullWidth ? 'w-full' : 'max-w-4xl'
        }`}
      >
        {!isInvoicePaymentAllocation && !isPayment ? (
          <InvoiceDetailsView
            tx={tx as IInvoice}
            breakPoint={matches}
            supplier={supplier}
            bgColor={bgColor}
            isPaid={isPaid}
            isPaymentInProgress={isPaymentInProgress}
            isPaymentScheduled={isPaymentScheduled}
            showAmountFieldOnPaymentSummaryPopover={showAmountFieldOnPaymentSummaryPopover}
            showSalesOrderRefNumber={showSalesOrderRefNumber}
            isCreditNote={isCreditNote}
            isAPInv={isAPInvoice || QuoteV2}
            showSummaryTabs={showSummaryTabs}
            hideQuantity={hideQuantity}
            QuoteV2={QuoteV2}
          />
        ) : isPayment ? (
          <PaymentView tx={tx as IInvoice} supplier={supplier} />
        ) : (
          <>
            <BatchPaymentHistoryView tx={tx as IInvoicePaymentAllocation} supplier={supplier} />
            <InvoiceDetailsFooter footer={footer} isStickyFooter={isStickyFooter} />
          </>
        )}
        {tx && !isAPInvoice && !isInvoicePaymentAllocation && (
          <InvoiceDetailsFooter isStickyFooter={isStickyFooter} footer={footer} />
        )}
      </div>
    </div>
  );
};
