import React, {useEffect} from 'react';
import ThemeContext from './ThemeContext';
import environment from '../../utils/environment';
import {capitalize} from 'lodash';

interface IThemeContextProviderProps {
  children: React.ReactNode;
}
export const ThemeContextProvider = (props: IThemeContextProviderProps) => {
  const {THEME: theme} = environment;

  useEffect(() => {
    if (environment.THEME?.rootClassName) {
      document.body.className = environment.THEME?.rootClassName;

      document.title = capitalize(environment.THEME?.rootClassName);

      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.setAttribute('href', `/favicon-${environment.THEME?.rootClassName}.ico`);
      }
    }
  }, [environment.THEME]);

  return (
    <ThemeContext.Provider
      value={{
        rootClassName: theme?.rootClassName || '',
        logo: theme?.logo || undefined,
        content: theme?.content || {},
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
