import React from 'react';

export const InfoEditIcon = (props: SVGProps) => (
  <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Spenda-Pay-Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-copy-4" transform="translate(-779.000000, -353.000000)">
        <g id="Group-3" transform="translate(779.000000, 353.000000)">
          <circle
            id="Oval"
            className="stroke-primary"
            strokeWidth="1.2"
            fill="#FFFFFF"
            cx="18"
            cy="18"
            r="17.4"
          ></circle>
          <path
            d="M25.9999869,14.1917098 C26.0012029,13.9790835 25.917723,13.7747164 25.7679743,13.6237527 L22.3759855,10.232009 C22.2250109,10.0822711 22.0206291,9.99879717 21.8079874,10.0000131 C21.5953457,9.99879717 21.3909638,10.0822711 21.2399893,10.232009 L18.9759968,12.4958379 L18.9759968,12.4958379 L10.2320257,21.2391774 C10.082277,21.390141 9.99879709,21.5945081 10.0000131,21.8071345 L10.0000131,25.1988782 C10.0000131,25.6406726 10.3581975,25.9988178 10.8000238,25.9988178 L14.1920126,25.9988178 C14.4183969,26.0111307 14.6393748,25.9268163 14.8000106,25.7668353 L23.4959818,17.0234959 L23.4959818,17.0234959 L25.7679743,14.7996639 C25.8409955,14.7221345 25.9004881,14.6329021 25.9439737,14.5356838 C25.9516815,14.4719207 25.9516815,14.4074614 25.9439737,14.3436983 C25.9477164,14.3064616 25.9477164,14.2689435 25.9439737,14.2317068 L25.9999869,14.1917098 Z M13.8640137,24.3989386 L11.6000212,24.3989386 L11.6000212,22.1351097 L19.5439949,14.1917098 L21.8079874,16.4555388 L13.8640137,24.3989386 Z M22.9359837,15.327624 L20.6719912,13.063795 L21.8079874,11.9358802 L24.0639799,14.1917098 L22.9359837,15.327624 Z"
            id="Shape"
            className="fill-primary"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
