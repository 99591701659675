import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Menu,
  MenuHandler,
  MenuList,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from 'spenda-ui-react';
import {Form, Formik, FormikProps, validateYupSchema, yupToFormErrors} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import _ from 'lodash';

import ServiceDetailTab from '../../screens/service-management/services-job-modal/ServiceDetailTab';
import MaterialAndLabour from '../../screens/service-management/services-job-modal/MaterialAndLabour';
import ServiceNotes from '../../screens/service-management/services-job-modal/ServiceNotes';
import {ICustomer} from '../../model/customer/Customer';
import {ILocation} from '../../model/address/Location';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {
  IServiceBooking,
  IServiceJob,
  ServiceJobScope,
  ServiceJobStatus,
} from '../../model/service-management/serviceJob';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import {AttachmentTypeEnum, DatTypes} from '../../model/constants/Constants';
import ServiceAttachmentTab from '../../screens/service-management/services-job-modal/ServiceAttachmentTab';
import {QuoteGeneratingDialog} from './QuoteGeneratingDialog';
import useServiceJobAPI from '../../services/useServiceJobAPI';
import {useServiceContext} from '../../context/serviceManagement/ServiceContext';
import {Actions} from '../../model/constants/Constants';
import LoadingOverlay from '../ui/LoadingOverlay';
import {discountToDiscountString} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';
import {QuoteRejectDialog} from './QuoteRejectDialog';
import {AlertDialog} from './AlertDialogSlideV2';
import {useServiceJobStatusAPI} from '../../services/useServiceJobStatusAPI';
import {ServiceStatus} from '../bookingDiary/BookingDiaryList';
import {useHistory} from 'react-router-dom';
import {CustomerDialog} from './CustomerDialog';
import {APInvoiceDetailView} from '../../screens/purchasing/APInvoiceDetailView';
import ServiceJobContactUpdateDialog from './ServiceJobContactUpdateDialog';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface ICreateServiceJobModal {
  open: boolean;
  handleClose: () => void;
  scope?: ServiceJobScope;
  serviceJobId?: number;
}

export interface IActionButtonsProps {
  btnLabel?: string;
  onClick?: () => void;
  dataAutoid?: string;
}

// Validation Schema
export const validationSchema = Yup.object({
  description: Yup.string().nullable().required('Job description (required)'),
  technicians: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        technicianUserID: Yup.number().required('Required'),
        action: Yup.string().required('Required'),
      }),
    )
    .compact(v => v.action != Actions.add)
    .min(1)
    .required('Technicians(Required)'),
  bookings: Yup.array()
    .of(
      Yup.object().shape({
        scheduleDate: Yup.string().required('Scheduled Date (required)'),
        startTime: Yup.string().required('Start Time (required)'),
        endTime: Yup.string().required('Finish Time (required)'),
      }),
    )
    .min(1, '*these fields are required to confirm & notify the customer')
    .required(),
  assetID: Yup.number().nullable().required('*these fields are required to confirm & notify the customer'),
});

export const CreateServiceJobModal: FC<ICreateServiceJobModal> = props => {
  const {open, handleClose, serviceJobId} = props;
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  // Context
  const {serviceJob, setServiceJob, setCustomerDetails} = useServiceContext();
  const [activeTab, setActiveTab] = useState<string>('details');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [openQuoteGeneratingDialog, setOpenQuoteGeneratingDialog] = useState<boolean>(false);
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<File[]>([]);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState<ICustomer>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCancelConfirmationDialog, setOpenCancelConfirmationDialog] = useState<boolean>(false);
  const [isDeleteJob, setIsDeleteJob] = useState<boolean>(false);
  const [isQuoteView, setIsQuoteView] = useState<boolean>(false);
  const [isOpenUnsavedDialog, setIsOpenUnsavedDialog] = useState<boolean>(false);
  const [selectedQuotePackageId, setSelectedQuotePackageId] = useState<number>();
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<number>();
  const [isShowUpdateContactDialog, setIsShowUpdateContactDialog] = useState<boolean>(false);
  const [updateCustomerContactInfo, setUpdateCustomerContactInfo] = useState<ILocation[]>();

  const formRef = useRef<FormikProps<IServiceJob>>(null);

  // API Services
  const {getCustomer, updateCustomer} = useCustomersAPI();
  const {getServiceJob, updateServiceJob, cancelServiceJob, setJobStatusAsScheduled, sendJobToCustomer} =
    useServiceJobAPI();
  const {uploadAttachments} = useAttachmentsAPI();
  const history = useHistory();

  const handleGenerateQuote = () => {
    setOpenQuoteGeneratingDialog(!openQuoteGeneratingDialog);
  };

  const handleViewInvoice = () => {
    const invoiceId = serviceJob?.linkedTransactions?.find(trans => trans.datTypeID == DatTypes.Invoice)?.id;
    setInvoiceId(invoiceId);
  };

  const handleUnsavedDialog = () => {
    setIsOpenUnsavedDialog(!isOpenUnsavedDialog);
  };

  const handleUpdateOpCustomer = async (_location: ILocation[]) => {
    if (selectedCustomerDetails?.ID && selectedCustomerDetails && _location) {
      selectedCustomerDetails?.AvailableCreditString && delete selectedCustomerDetails?.AvailableCreditString;
      selectedCustomerDetails?.AvailableCredit && delete selectedCustomerDetails?.AvailableCredit;
      selectedCustomerDetails?.TotalOverdueAmount && delete selectedCustomerDetails?.TotalOverdueAmount;
      selectedCustomerDetails?.TotalDueAmount && delete selectedCustomerDetails?.TotalDueAmount;
      selectedCustomerDetails?.CustomerClass && delete selectedCustomerDetails?.CustomerClass;
      selectedCustomerDetails?.Locations && delete selectedCustomerDetails?.Locations;
      const res = await updateCustomer(
        selectedCustomerDetails?.ID,
        {...selectedCustomerDetails, Locations: _location},
        ApplicationInfo.WebsiteId,
      );
      if (res) {
        const custDetails = await getCustomer(selectedCustomerDetails?.ID);
        const selCustomer = {...selectedCustomerDetails};
        selCustomer.Locations = custDetails.Locations || [];
        selCustomer.DefaultLocation = custDetails?.DefaultLocation || {};
        setSelectedCustomerDetails(selCustomer);
        setCustomerDetails(selCustomer);
      }
    }
  };

  const fetchServiceJob = async () => {
    try {
      const serviceJob = await getServiceJob(serviceJobId!);
      if (serviceJob) {
        if (serviceJob?.bookings?.length) {
          await serviceJob.bookings.forEach((booking: IServiceBooking, index) => {
            booking.index = index;
            booking.isActive = true;
            booking.scheduleDate = moment.utc(booking.startDateTime_utc).local().format('YYYY-MM-DD');
            booking.startTime = moment.utc(booking.startDateTime_utc).local().format('HH:mm');
            booking.endTime = moment.utc(booking.endDateTime_utc).local().format('HH:mm');
          });
        }
        const discountString = () => {
          if (!serviceJob) return '';
          return (serviceJob.discountString = discountToDiscountString(
            Number(serviceJob.discount),
            serviceJob.discountMode!,
          ));
        };
        const customerDetails = await getCustomer(serviceJob.customerID!);
        if (!serviceJob.onSiteBusinessContactID) {
          const primaryContactID = customerDetails?.Contacts?.find(
            contact => contact.IsPrimaryContact,
          )?.BusinessContactID?.toString();
          serviceJob.onSiteBusinessContactID = primaryContactID;
        }
        serviceJob.technicians = _.sortBy(serviceJob.technicians, obj => {
          return obj.technicianUserID === serviceJob.primaryTechnicianUserID ? 0 : 1;
        });
        serviceJob.technicians.forEach(tech => (tech.action = Actions.add));
        if (!serviceJob.lines.length) {
          serviceJob.lines.push({
            code: '',
            shortDescription: '',
            inventoryID: 0,
            quantity: 0,
            sellPriceEx: 0,
            lineTotalEx: 0,
            lineTotalInc: 0,
            uoM: '',
            isActive: true,
            sellPriceExString: '',
            lineTotalExString: '',
            sequenceNumber: 0,
            serviceJobID: serviceJob.serviceJobID!,
            serviceQuoteLineID: null,
          });
        }
        setServiceJob({
          ...serviceJob,
          discountString: discountString(),
        });
        setSelectedCustomerDetails(customerDetails);
        setCustomerDetails(customerDetails);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching service job', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (serviceJobId) {
      // Fetch service job details
      setIsLoading(true);
      fetchServiceJob();
    }
  }, [serviceJobId]);

  const saveServiceAttachments = async () => {
    if (addedFilesToUpload?.length) {
      const formData = new FormData();

      addedFilesToUpload?.forEach((attachment: File) => {
        formData.append('Files', attachment, attachment.name);
      });

      const attachments = addedFilesToUpload?.map((n, i) => {
        return {
          Caption: addedFilesToUpload?.[i]?.name,
          Note: '',
          Action: 0,
        };
      });

      const jsonRequest = {
        ServiceJobID: serviceJobId,
        Attachments: attachments || [],
      };

      formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.ServiceJob));
      formData.append('JsonRequest', JSON.stringify(jsonRequest));

      return await uploadAttachments(formData, {'content-type': 'multipart/form-data'});
    }
  };

  const onSave = async (values: IServiceJob) => {
    setIsLoading(true);
    try {
      const [updatedJob] = await updateJob(values);
      if (updatedJob) {
        handleClose();
      }
    } catch (error) {
      console.error('Error during save API call:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateJob = async (values: IServiceJob) => {
    const bookings = [...(values?.bookings || [])];
    values.bookings = bookings?.filter(
      booking => booking.startTime != '' || booking.endTime != '' || booking.scheduleDate != '',
    );
    await values?.bookings?.map((booking: IServiceBooking) => {
      booking.startDateTime_utc = moment(`${booking.scheduleDate} ${booking.startTime}`)
        .utc()
        .format('YYYY-MM-DD HH:mm');
      booking.endDateTime_utc = moment(`${booking.scheduleDate} ${booking.endTime}`).utc().format('YYYY-MM-DD HH:mm');
      booking.action = booking.serviceBookingID ? (booking.isActive ? Actions.update : Actions.remove) : Actions.add;
    });
    values.technicians = values?.technicians && _.uniqBy(values?.technicians, 'technicianUserID');
    return await Promise.all([updateServiceJob(values), saveServiceAttachments()]);
  };

  const handleDeleteJob = async () => {
    setIsDeleteJob(false);
    setIsLoading(true);
    if (serviceJobId) {
      try {
        // Set isActive to false for a successful delete
        await updateServiceJob({isActive: false, serviceJobID: serviceJobId});
        Toast.info('Service Job deleted successfully!');
      } catch (error) {
        console.error('Error during delete API call:', error);
      }
      setIsLoading(false);
      handleClose();
    }
  };

  const handleCancelJob = async (cancelReason?: string) => {
    setIsLoading(true);
    if (serviceJobId) {
      if (serviceJob?.status === ServiceJobStatus.Scheduled) {
        try {
          await cancelServiceJob(serviceJobId, cancelReason!);
          Toast.info('Service Job cancelled successfully!');
        } catch (error) {
          console.error('Error during cancel API call:', error);
        }
      }
      setIsLoading(false);
      handleClose();
    }
  };

  const notifyCustomer = async (value: IServiceJob) => {
    if (!serviceJobId) return;

    setIsLoading(true);
    try {
      await updateJob(value);
      await setJobStatusAsScheduled(serviceJobId);
      await sendJobToCustomer(serviceJobId, false);
      Toast.info('Customer Notified!');
      handleClose();
    } catch (error) {
      console.error('Error during scheduling Job:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotifyCustomer = async (value: IServiceJob) => {
    const {PrimaryContactEmailAddress, PrimaryContactPhone, Contacts} = selectedCustomerDetails || {};

    if (QuoteV289367 && (!PrimaryContactEmailAddress || !PrimaryContactPhone)) {
      const customerContactInfo = Contacts?.map(contact => {
        return {
          ...contact,
          EmailAddress: contact.EmailAddress ?? '',
          Phone1: contact.Phone1 ?? '',
        };
      });
      setUpdateCustomerContactInfo(customerContactInfo);
      setIsShowUpdateContactDialog(true);
      return;
    }
    await notifyCustomer(value);
  };

  const submitViaUpdateContactDialog = async (value: IServiceJob) => {
    setIsShowUpdateContactDialog(false);
    await notifyCustomer(value);
  };

  const tabs = [
    {
      label: 'Details',
      value: 'details',
      desc: (
        <ServiceDetailTab
          handleUpdateOpCustomer={handleUpdateOpCustomer}
          customerDetails={selectedCustomerDetails}
          setIsLoading={setIsLoading}
        />
      ),
    },
    {
      label: 'Materials & Labour',
      value: 'materials & Labour',
      desc: <MaterialAndLabour />,
    },
    {
      label: 'Attachments',
      value: 'attachments',
      desc: (
        <div className="flex h-[300px] flex-col gap-y-2 overflow-auto pt-7 first:gap-y-5 lg:px-4 xl:px-12">
          <ServiceAttachmentTab
            addedFilesToUpload={addedFilesToUpload}
            setAddedFilesToUpload={setAddedFilesToUpload}
            setIsSaving={setIsLoading}
          />
        </div>
      ),
    },
    {
      label: 'Notes',
      value: 'notes',
      desc: <ServiceNotes />,
    },
  ];

  // For customer action buttons
  const ActionButtons: React.FC<IActionButtonsProps> = ({btnLabel, onClick, dataAutoid}) => {
    return (
      <li
        onClick={onClick}
        className="w-full cursor-pointer list-none border-b border-[#ECECEC] px-3 py-2 text-left text-base font-semibold text-black-800 hover:bg-[#E3EEF5]"
        data-autoid={`${dataAutoid}`}
      >
        {btnLabel}
      </li>
    );
  };

  const _jobHeader = (_values: IServiceJob) => {
    const linkedQuotes =
      _values?.linkedTransactions && _values?.linkedTransactions?.filter(item => item.datTypeID != DatTypes.Invoice);
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center justify-start gap-2.5">
          <Typography variant="h2" className="font-light" data-autoid="lblCustomerName">
            {typeof selectedCustomerDetails?.IsIndividual === 'string' &&
            selectedCustomerDetails.IsIndividual.toLowerCase() === 'true'
              ? `${selectedCustomerDetails?.Contacts?.[0]?.FirstName} ${selectedCustomerDetails?.Contacts?.[0]?.LastName}`
              : selectedCustomerDetails?.Name}
          </Typography>
          <Typography variant="h3" className="py-[2px] font-medium" data-autoid="lblRefNumber">
            [{_values?.refNumber}]
          </Typography>
          {_values?.isWaitingForStock ? (
            <ServiceStatus status={ServiceJobStatus.AwaitingStock} />
          ) : !_values?.isWaitingForStock && _values?.bookings && _values?.bookings.length > 0 && !_values?.isSent ? (
            <ServiceStatus status={ServiceJobStatus.ScheduledTentative} />
          ) : (
            <ServiceStatus status={_values?.status} />
          )}
        </div>
        <div className="flex gap-2.5">
          {linkedQuotes && linkedQuotes?.length > 0 && (
            <div className="flex gap-2.5">
              <Menu
                open={isQuoteView}
                handler={() => {
                  setIsQuoteView(!isQuoteView);
                }}
              >
                <MenuHandler className="relative flex w-[188px] cursor-pointer items-center justify-center gap-[1.25rem] rounded-md border border-primary p-2">
                  <div>
                    <Typography className="text-center font-semibold text-primary">View Quote</Typography>
                    <div className="absolute right-3 flex items-center pt-0.5">
                      <div className={`rotate-0 cursor-pointer`} data-autoid={'imgViewQuote'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                          <path
                            fill="#1C77AB"
                            fillRule="evenodd"
                            d="M4.2889 5.4688h7.4533a.6666.6666 0 0 1 .4667 1.14l-3.72 3.7199a.6663.6663 0 0 1-.9467 0l-3.72-3.72a.6667.6667 0 0 1 .4667-1.14Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </MenuHandler>
                <MenuList className="z-[9999] w-[188px] overflow-hidden border-primary p-0">
                  {linkedQuotes?.map((quote, index) => {
                    return (
                      <ActionButtons
                        key={index}
                        btnLabel={`Quote - ${quote?.refNumber}`}
                        dataAutoid={`btnQuoteView${quote.id}`}
                        onClick={() => {
                          if (formRef?.current?.dirty) {
                            setSelectedQuotePackageId(quote.id);
                            handleUnsavedDialog();
                          } else {
                            if (quote?.isSent) history.push(`/manage-quotes/quote-details/${quote.id}`);
                            else history.push(`/manage-quotes/quote/${quote.id}`);
                          }
                        }}
                      />
                    );
                  })}
                </MenuList>
              </Menu>
            </div>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setOpenCustomer(true);
            }}
          >
            Edit customer
          </Button>
          {openCustomer && (
            <CustomerDialog
              customerId={_values?.customerID}
              open={openCustomer}
              onClose={() => setOpenCustomer(false)}
              setOpenAccountCustomer={() => null}
              setCustomer={setSelectedCustomerDetails}
              isQuoteCustomer
              onCustomerUpdate={() => {
                setOpenCustomer(false);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const _jobFooter = (_values: IServiceJob, setFieldValue: (fieldName: string, fieldValue: string) => void) => {
    const linkedQuotes = serviceJob?.linkedTransactions?.filter(item => item.datTypeID != DatTypes.Invoice);
    const {setInProgress, setScheduled, setAsPaused, setAsReschedule, setAsCompleted} = useServiceJobStatusAPI();

    const [showCompleteJobDialog, setShowCompleteJobDialog] = useState<boolean>(false);

    const checkIfValidJob = useMemo(() => {
      return _values?.assetID &&
        _values?.bookings?.length &&
        _values?.customerID &&
        _values?.description?.length &&
        _values?.primaryTechnicianUserID &&
        _values?.technicians?.length
        ? false
        : true;
    }, [JSON.stringify(_values)]);

    const handleAction = async (action: string) => {
      setIsLoading(true);
      try {
        if (action === ServiceJobStatus.InProgress) {
          const res = await setInProgress(_values?.serviceJobID!);
          if (res.isSuccess) setFieldValue('status', ServiceJobStatus.InProgress);
        } else if (action === ServiceJobStatus.Scheduled) {
          const res = await setScheduled(_values?.serviceJobID!);
          if (res.isSuccess) setFieldValue('status', ServiceJobStatus.Scheduled);
        } else if (action === ServiceJobStatus.Paused) {
          const res = await setAsPaused(_values?.serviceJobID!);
          if (res.isSuccess) setFieldValue('status', ServiceJobStatus.Paused);
        } else if (action === ServiceJobStatus.Reschedule) {
          const res = await setAsReschedule(_values?.serviceJobID!);
          if (res.isSuccess) setFieldValue('status', ServiceJobStatus.Reschedule);
        } else if (action === ServiceJobStatus.Completed) {
          const res = await setAsCompleted(_values?.serviceJobID!);
          if (res.isSuccess) {
            setIsLoading(true);
            fetchServiceJob();
          }
        }
      } catch (error) {
        console.error('Error during action API call:', error);
      }
      setIsLoading(false);
      if (action === ServiceJobStatus.Completed) setShowCompleteJobDialog(false);
    };

    const isCompleted = _values?.status === ServiceJobStatus.Completed;

    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex gap-2.5">
          <Button variant="outlined" className="bg-white" onClick={handleClose}>
            Close
          </Button>
          {!isCompleted && (
            <Button
              variant="outlined"
              color="error"
              className="bg-white"
              onClick={() => {
                if (serviceJob?.status === ServiceJobStatus.Scheduled) {
                  setOpenCancelConfirmationDialog(true);
                } else {
                  setIsDeleteJob(!isDeleteJob);
                }
              }}
            >
              {serviceJob?.status === ServiceJobStatus.Scheduled ? 'Cancel Job' : 'Delete Job'}
            </Button>
          )}
        </div>
        {!isCompleted ? (
          <div className="flex gap-2.5">
            {_values?.status !== ServiceJobStatus.Open && _values?.status !== ServiceJobStatus.Reschedule && (
              <Menu open={isMenuOpen} handler={() => setIsMenuOpen(!isMenuOpen)}>
                <MenuHandler className="relative flex w-[188px] cursor-pointer items-center justify-center gap-[1.25rem] rounded-md bg-primary">
                  <div>
                    <Typography className="text-center font-semibold text-white">Action Job</Typography>
                    <div className="absolute right-5 flex items-center justify-end gap-2">
                      <div className={`${isMenuOpen ? '-rotate-180' : ''} cursor-pointer`} data-autoid={'imgActionJob'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                          <path
                            fill="#fff"
                            fillRule="evenodd"
                            d="M4.2889 5.4688h7.4533a.6666.6666 0 0 1 .4667 1.14l-3.72 3.7199a.6663.6663 0 0 1-.9467 0l-3.72-3.72a.6667.6667 0 0 1 .4667-1.14Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </MenuHandler>
                {isMenuOpen && (
                  <MenuList className="z-[9999] w-[188px] overflow-hidden border-primary p-0">
                    {_values?.status !== ServiceJobStatus.InProgress && (
                      <ActionButtons
                        onClick={() => handleAction(ServiceJobStatus.InProgress)}
                        btnLabel={_values?.status === ServiceJobStatus.Paused ? 'Resume job' : 'Start job'}
                        dataAutoid="btnStartJob"
                      />
                    )}
                    {_values?.status !== ServiceJobStatus.Paused && _values?.status !== ServiceJobStatus.Scheduled && (
                      <ActionButtons
                        onClick={() => handleAction(ServiceJobStatus.Paused)}
                        btnLabel="Pause job"
                        dataAutoid="btnPauseJob"
                      />
                    )}

                    <ActionButtons
                      onClick={() => setShowCompleteJobDialog(true)}
                      btnLabel="Complete job"
                      dataAutoid="btnCompleteJob"
                    />

                    {(!linkedQuotes || linkedQuotes.length < 5) && (
                      <ActionButtons
                        onClick={handleGenerateQuote}
                        btnLabel="Generate quote"
                        dataAutoid="btnGenerateQuote"
                      />
                    )}
                    <ActionButtons
                      onClick={() => handleAction(ServiceJobStatus.Reschedule)}
                      btnLabel="Reschedule job"
                      dataAutoid="btnRescheduleJob"
                    />
                  </MenuList>
                )}
              </Menu>
            )}
            <Button onClick={() => onSave(_values)}>Save & Close</Button>

            {_values?.status !== ServiceJobStatus.Scheduled &&
              _values?.status !== ServiceJobStatus.InProgress &&
              _values?.status !== ServiceJobStatus.Paused && (
                <Button disabled={checkIfValidJob} type="submit">
                  Confirm & Notify Customer
                </Button>
              )}
          </div>
        ) : (
          <div className="flex gap-2.5">
            {(!linkedQuotes || linkedQuotes?.length < 5) && (
              <Button onClick={handleGenerateQuote}>Generate quote</Button>
            )}
            {serviceJob?.totalInc ? <Button onClick={handleViewInvoice}>View invoice</Button> : null}
          </div>
        )}

        {showCompleteJobDialog && (
          <AlertDialog
            title="Complete Job"
            headingTextSize="h2"
            headingClassess="text-black justify-center"
            data-autoid={'dlgCompleteJob'}
            actions={[
              {
                label: 'Cancel',
                variant: 'outlined',
                action: () => setShowCompleteJobDialog(false),
              },
              {
                label: 'Continue',
                loading: isLoading,
                action: () => handleAction(ServiceJobStatus.Completed),
              },
            ]}
            dialogBodyClassess="flex justify-center"
            contentClass="w-[400px]"
            content={`Are you sure you would like to complete this service job ${serviceJob?.refNumber}? This action cannot be undone. `}
          />
        )}
        {isDeleteJob && (
          <AlertDialog
            title="Delete Job"
            headingTextSize="h2"
            headingClassess="text-black justify-center"
            data-autoid={'dlgCompleteJob'}
            dialogClassess="!max-w-[497px] !min-w-[20%]"
            actions={[
              {
                label: 'Cancel',
                variant: 'outlined',
                action: () => setIsDeleteJob(false),
              },
              {
                label: 'Delete',
                variant: 'outlined',
                color: 'error',
                action: () => handleDeleteJob(),
              },
            ]}
            dialogBodyClassess="flex justify-center"
            contentClass="max-w-[400px] text-base"
            content={`Are you sure you would like to delete this service job?`}
          />
        )}
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      handler={handleClose}
      className="!mx-5 !w-full !max-w-[96%] xl:!max-w-[1245px]"
      dismiss={{enabled: false}}
      data-autoid={'dlgCreateServiceJobDialog'}
    >
      <Formik
        initialValues={serviceJob!}
        onSubmit={handleNotifyCustomer}
        validateOnBlur
        validateOnChange
        enableReinitialize
        validate={async value => {
          try {
            await validateYupSchema(value, validationSchema, false, value);
          } catch (err) {
            return yupToFormErrors(err); //for rendering validation errors
          }
          return {};
        }}
        validateOnMount
        innerRef={formRef}
      >
        {({values, setFieldValue}) => (
          <Form>
            <LoadingOverlay isLoading={isLoading} overlayBgColor={'rgba(0, 0, 0, 0.1)'} isAPOnboardingV1={true}>
              <DialogHeader className="border-b border-[#EAEAEA] text-xl font-light lg:text-2xl">
                {_jobHeader(values)}
              </DialogHeader>
              <DialogBody className="relative justify-center border-b border-[#f1f1f1]">
                <div>
                  <Tabs value={activeTab} id="custom-animation">
                    <TabsHeader className="gap-10 bg-transparent lg:px-4 xl:px-12">
                      {tabs.map(({label, value}) => (
                        <Tab
                          key={value}
                          value={value}
                          onClick={() => setActiveTab(value)}
                          className={`w-max border-b border-transparent p-0 font-medium ${
                            activeTab === value ? 'border-primary text-primary' : ''
                          }`}
                          data-autoid={`lbl-${value}`}
                        >
                          {label}
                        </Tab>
                      ))}
                    </TabsHeader>
                    <TabsBody
                      animate={{
                        initial: {y: 100},
                        mount: {y: 0},
                        unmount: {y: 10},
                      }}
                    >
                      {tabs.map(({value, desc}) => (
                        <TabPanel key={value} value={value} className="px-0 pb-5 pt-10">
                          {desc}
                        </TabPanel>
                      ))}
                    </TabsBody>
                  </Tabs>
                </div>
              </DialogBody>
              <DialogFooter className={`mx-2.5 mb-2.5 justify-between rounded-lg bg-spenda-footerBg p-2.5`}>
                {_jobFooter(values, setFieldValue)}
              </DialogFooter>
            </LoadingOverlay>
            {QuoteV289367 && isShowUpdateContactDialog && (
              <ServiceJobContactUpdateDialog
                handler={value => {
                  if (value) {
                    setIsShowUpdateContactDialog(false);
                    submitViaUpdateContactDialog(values);
                  } else {
                    setIsShowUpdateContactDialog(false);
                  }
                }}
                contacts={updateCustomerContactInfo}
                formValues={values}
              />
            )}
          </Form>
        )}
      </Formik>
      {openCancelConfirmationDialog && (
        <QuoteRejectDialog
          handleRejectQuote={handleCancelJob}
          handleCancel={() => setOpenCancelConfirmationDialog(false)}
          isLoading={isLoading}
          isCancelServiceJob
        />
      )}
      {openQuoteGeneratingDialog && (
        <QuoteGeneratingDialog
          serviceJob={serviceJob!}
          open={openQuoteGeneratingDialog}
          handleCancel={() => {
            setOpenQuoteGeneratingDialog(false);
          }}
          redirectToQuote={(quotePackageId: number) => {
            setOpenQuoteGeneratingDialog(false);
            history.push(`/manage-quotes/quote/${quotePackageId}`);
          }}
        />
      )}
      {isOpenUnsavedDialog && (
        <AlertDialog
          title="Unsaved changes"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          data-autoid={'dlgUnsavedChanges'}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () => setIsOpenUnsavedDialog(false),
            },
            {
              label: 'Continue',
              action: () => {
                selectedQuotePackageId && history.push(`/manage-quotes/quote/${selectedQuotePackageId}`);
              },
            },
          ]}
          dialogBodyClassess="flex justify-center"
          contentClass="w-[400px]"
          content={`You have made changes that haven’t been saved yet. Are you sure you want to continue to view quote?`}
        />
      )}
      {invoiceId && (
        <APInvoiceDetailView
          open={Boolean(invoiceId)}
          onClose={() => {
            setInvoiceId(undefined);
          }}
          isServiceJobInvoice
          apInvoiceId={{transId: invoiceId!}}
        />
      )}
    </Dialog>
  );
};
