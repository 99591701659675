import React, {FunctionComponent, PropsWithChildren} from 'react';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from 'spenda-ui-react';
import {variant} from 'spenda-ui-react/types/components/typography';
import {variant as buttonVariant, size} from 'spenda-ui-react/types/components/button';
import {color} from 'spenda-ui-react/types/components/button';

export interface IDialogActionV2 extends Omit<React.ComponentProps<'button'>, 'ref'> {
  color?: color;
  label: string;
  loading?: boolean;
  size?: size;
  variant?: buttonVariant;
  action?: (value?: any) => void;
}

export interface IDialogPropsV2 extends PropsWithChildren {
  dialogClassess?: string;
  svg?: any;
  title?: string | React.ReactNode;
  actions?: IDialogActionV2[];
  overflowY?: 'auto' | 'hidden' | 'scroll';
  dialogActionsAlignment?: 'justify-end' | 'justify-start' | 'justify-center' | 'justify-between';
  paddingX?: number;
  paddingTopHeader?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  footer?: React.ReactNode | undefined;
  header?: React.ReactNode | undefined;
  showCancel?: boolean;
  onCancel?: () => void;
  handler?: (value: any) => void;
  isAccountsPayable?: boolean;
  headerFontWeight?: number;
  alignContentText?: string;
  alignContentItems?: string;
  showTitleBottomBorder?: boolean;
  id?: string | undefined;
  headingTextSize?: 'h1' | 'h2' | 'h3' | 'small';
  headingClassess?: string;
  dialogBodyClassess?: string;
  headerChildren?: React.ReactNode;
  footerClass?: string;
}

export const AlertDialogSlideV2: FunctionComponent<IDialogPropsV2> = prop => {
  const {
    svg,
    headingTextSize,
    title,
    children,
    size,
    headingClassess,
    dialogBodyClassess,
    dialogActionsAlignment,
    footer,
    actions,
    dialogClassess,
    headerChildren,
    handler,
    footerClass,
    ...rest
  } = prop;
  // using spenda-ui-react Dialog
  const [, setOpen] = React.useState(true);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open handler={handler ?? closeDialog} size={size ?? 'md'} className={dialogClassess ?? ''} {...rest}>
      {title && (
        <DialogHeader className={`${headingClassess ?? 'justify-center'} border-b border-[#EAEAEA]`}>
          {svg && <img className="mr-2" src={svg} alt="TimeOut" />}
          <Typography variant={headingTextSize ?? 'h2'}>{title}</Typography>
          {headerChildren}
        </DialogHeader>
      )}
      <DialogBody className={dialogBodyClassess}>{children}</DialogBody>
      <DialogFooter
        className={`${dialogActionsAlignment} ${footerClass} mx-2.5 mb-2.5 rounded-lg bg-spenda-footerBg p-2.5`}
      >
        {footer}
        {!footer &&
          actions?.map(({action, className, label, ...buttonProps}: IDialogActionV2, index: number) => (
            <Button
              {...buttonProps}
              key={index}
              onClick={action}
              className={`${className} ${buttonProps.variant === 'outlined' ? ' bg-white focus:ring-0' : ''}`}
            >
              {label}
            </Button>
          ))}
      </DialogFooter>
    </Dialog>
  );
};

interface IAlertDialog extends IDialogPropsV2 {
  content?: string;
  body?: React.ReactNode;
  contentClass?: string;
  contextTextVariant?: variant;
}

export const AlertDialog: React.FC<IAlertDialog> = prop => {
  const {
    actions,
    title,
    content,
    size,
    id,
    body,
    dialogActionsAlignment,
    headingTextSize,
    contentClass,
    contextTextVariant,
    children,
    ...rest
  } = prop;
  return (
    <AlertDialogSlideV2
      id={id}
      title={title}
      headingTextSize={headingTextSize ?? 'h1'}
      headingClassess="justify-center"
      dialogActionsAlignment={dialogActionsAlignment ?? 'justify-between'}
      size={size}
      actions={actions}
      {...rest}
    >
      {content ? (
        <Typography
          variant={contextTextVariant ?? 'h3'}
          className={`py-8 text-center font-normal text-black-800 ${contentClass}`}
        >
          {content}
        </Typography>
      ) : body ? (
        body
      ) : (
        children
      )}
    </AlertDialogSlideV2>
  );
};
